import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../Auth/authSlice';
import notificationReducer from './../Notifications/notificationSlice';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../api/apiSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware().concat(apiSlice.middleware)
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
