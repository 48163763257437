import React from 'react';
import { useParams } from 'react-router-dom';
import ViewHouse from './ViewHouse';
import { Loader } from '../HouseLoader';
import { useGetHouseQuery } from '../../api/apiSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';

// TODO: ViewHouse component should query its own data or move it here.

const HouseDetails = () => {
  const { houseId } = useParams<{houseId: string}>();
  const { data: house, isFetching } = useGetHouseQuery(houseId ?? skipToken)

  return (
    <>
      {house && <ViewHouse house={house} />}
      {!house && isFetching ? (
        <div className="text-center house-details-loader">
          <Loader />
        </div>
      ) : null}
    </>
  );
};

export { ViewHouse };

export default HouseDetails;
