/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import Container from 'react-bootstrap/Container';
import {
  FaFacebookSquare,
  FaTwitter,
  FaLinkedin,
  FaInstagram
} from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';

const footerCss = {
  link: css({
    textDecoration: 'none !important'
  }),
  footerLogo: css({
    height: '1.2rem',
    width: '2rem',
    marginBottom: '0.2rem'
  }),
  footerLogoText: css({
    color: '#00A69C',
    paddingLeft: '0.4rem',
    marginLeft: '-1rem'
  }),
  copyrightArea: css({
    minHeight: '20px',
    backgroundColor: 'black',
    color: '#fff',
    padding: '1rem'
  })
};

const Footer: React.FC = () => (
  <div>
    <Container>
      <Row className="d-block d-md-flex p-3 align-items-center">
        <Col md={4}>
          <h5>
            <Link to={ROUTES.LANDING} css={footerCss.link}>
              <img alt="logo" src="../logo192.png" css={footerCss.footerLogo} />
              <span css={footerCss.footerLogoText}>entalsug</span>
            </Link>
          </h5>
        </Col>
        <Col
          md={6}
          className="d-flex justify-content-start justify-content-md-center"
        >
          <div className="mr-md-4 mr-3">
            <Link to={ROUTES.HOME} className="d-flex">
              Home{' '}
              <span className="ml-md-4 ml-3 footer-dashes d-md-inline">|</span>
            </Link>
          </div>
          <div className="mr-md-4 mr-3">
            <Link to={ROUTES.REQUEST} className="d-flex">
              Request{' '}
              <span className="ml-md-4 ml-3 footer-dashes d-md-inline">|</span>
            </Link>
          </div>
          <div className="mr-md-4 mr-3">
            <Link to={ROUTES.HOUSES} className="d-flex">
              House Listing{' '}
              <span className="ml-md-4 ml-3 footer-dashes d-md-inline">|</span>
            </Link>
          </div>
          <div className="mr-md-4 mr-3">
            <Link to={ROUTES.ABOUT} className="d-flex">
              About
            </Link>
          </div>
        </Col>
        <Col>info@rentalsug.com +256778072363</Col>
      </Row>
    </Container>
    <div
      className="d-block d-md-flex align-items-center"
      css={footerCss.copyrightArea}
    >
      <Container>
        <Row className="d-block d-md-flex">
          <Col>
            &copy;
            {` ${new Date().getFullYear()} RentalsUg. All rights reserved.`}
          </Col>
          <Col className="d-flex justify-content-start justify-content-md-end">
            <div className="mr-4">
              <a
                target="_blank"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                href="https://www.facebook.com/rentalsugOfficial/"
              >
                <FaFacebookSquare />
              </a>
            </div>
            <div className="mr-4">
              <a
                target="_blank"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                href="https://twitter.com/RentalsUg"
              >
                <FaTwitter />
              </a>
            </div>
            <div className="mr-4">
              <a
                target="_blank"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/rentalsug/"
              >
                <FaLinkedin />
              </a>
            </div>
            <div className="">
              <a
                target="_blank"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                href="https://www.instagram.com/rentalsug/"
              >
                <FaInstagram />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);
export default Footer;
