import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Carousel,
  Row,
  Col,
  Card,
  Container,
  Button,
  Spinner
} from 'react-bootstrap';

export const HouseLoader = () => (
  <Card className="shadow rounded h-100">
    <Card.Img as={Carousel}>
      <Carousel.Item>
        <Skeleton className="d-block w-100 house-container-image" />
      </Carousel.Item>
    </Card.Img>
    <Card.Body>
      <Row>
        <Col xs={9} md={6} className="text-capitalize house-details-type">
          <Skeleton />
        </Col>
        <Col xs={3} md={6}>
          <h5 style={{ float: 'right' }}>
            <Skeleton />
          </h5>
        </Col>
      </Row>
      <h4 className="font-weight-bold">
        <Skeleton />
      </h4>
      <span>
        <Skeleton />
      </span>
      <hr />
      <Row>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export const HousesLoader = () => (
  <Container className="pl-0 pr-0">
    <Row className="houses-list">
      {new Array(3).fill(null).map((_, key) => (
        <Col key={key} xs={12} sm={6} lg={4} className="mb-5">
          <HouseLoader key={key} />
        </Col>
      ))}
    </Row>
  </Container>
);

export const Loading = () => <p>Loading...</p>;

export const Loader = () => (
  <Button
    style={{
      backgroundColor: '#3A8D8C',
      borderColor: '#3A8D8C',
    }}
    disabled
    className="mt-4"
  >
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    Loading...
  </Button>
);
