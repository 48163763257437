/* tslint:disable:no-submodule-imports */
import * as app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import {
  HouseState,
  House,
  UserHouseOfInterest,
  optionType,
  requestType
} from '../Houses/house.types';
import { convertMediaFilesObjectsToArray } from '../util';
import {
  HOUSE_STATUS,
  HOUSE_INTEREST_STATUS,
  HOUSES_BATCH_LIMIT
} from '../Houses/house.constants';

type AuthAndDbUserMerged = {
  uid: string;
  email: string | null;
  username?: string;
  phoneNumber?: string;
  photoUrl: string;
  roles: {
    [key: string]: string;
  };
};

type RequestType = {
  uid: string;
  location: string[];
  description: string;
  moveInDates: string;
  numberOfRooms: string;
  phoneNumber: string;
  priceRange: string;
  selfContainedStatus: string;
  status: string;
  username: string;
  createdAt: string;
  messages?: string[];
  updatedAt?: string;
}

type Snapshot = app.firestore.QuerySnapshot<app.firestore.DocumentData>;

type HouseInterest = {
  houseId: string,
  houseImage: string,
  rentFee: number,
  location: string,
  user: AuthAndDbUserMerged,
  phoneNumber: string,
  houseURL: string
}

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
// TODO: Rename listeners - they are no-longer listeners
class Firebase {
  static getInstance(): Firebase {
    if (!Firebase.instance) {
      Firebase.instance = new Firebase();
    }
    return Firebase.instance;
  }

  static computeHousesFromSnapshot(snapshot: Snapshot): HouseState[] {
    const houses: HouseState[] = [];

    snapshot.forEach((doc: any) => {
      houses.push({
        numberOfUsersInterested: 0,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toString(),
        updatedAt: doc.data().updatedAt
          ? doc.data().updatedAt.toDate().toString()
          : '',
        uid: doc.id
      });
    });

    return houses;
  }

  static computeRequestFromSnapshot(snapshot: Snapshot): RequestType[] {
    const requests: RequestType[] = []
    snapshot.forEach((doc: any) => {
      requests.push({
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toString(),
        updatedAt: doc.data().updatedAt
          ? doc.data().updatedAt.toDate().toString()
          : '',
        uid: doc.id,
      });
    });

    return requests;
  }

  auth: app.auth.Auth;
  db: app.firestore.Firestore;
  googleProvider: app.auth.GoogleAuthProvider;
  facebookProvider: app.auth.FacebookAuthProvider;
  storage: app.storage.Storage;
  fieldValue: any;
  housesNext: any;
  endOfPagination: boolean;

  signUpUser = ({ email, password }: { email: string, password: string }) =>
    this.auth.createUserWithEmailAndPassword(email, password)
  signInUser = ({ email, password }: { email: string, password: string }) =>
    this.auth.signInWithEmailAndPassword(email, password)
  sendPasswordResetEmail = (email: string) =>
    this.auth.sendPasswordResetEmail(email)

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  updateUserAfterGoogleAuth = (authUser: any) => this.user(authUser.uid).set(
    {
      username: authUser.displayName,
      email: authUser.email,
      photoUrl: authUser.photoURL
    },
    { merge: true }
  );

  updateUserAfterSignup = (userData: any) => this.user(userData.uid).set(
    {
      username: userData.name,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      roles: {}
    },
    { merge: true }
  );

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);
  doSignOut = () => this.auth.signOut();

  onAuthUserListener = (
    next: (user: AuthAndDbUserMerged) => void,
    fallback: () => void
  ) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            if (dbUser) {
              const { roles = {}, username, phoneNumber = '' } = dbUser;
              const authUserAndDbUserMerged = {
                uid: authUser.uid,
                email: authUser.email,
                roles,
                username,
                photoUrl: authUser.photoURL || '',
                phoneNumber
              };
              next(authUserAndDbUserMerged);
            }
          });
      } else {
        fallback();
      }
    })

    getCurrentUser = () => {
      return new Promise(async (resolve, reject) => {
        const currentUser = this.auth.currentUser
        if (currentUser) {
          const user = await this.getAuthUserAndDbUserMerged(currentUser)
          resolve(user)
        } else {
          const unsubscribe = this.auth.onAuthStateChanged(async (authUser) => {
            unsubscribe()
            if(authUser) {
              const user = await this.getAuthUserAndDbUserMerged(authUser)
              resolve(user)
            } else {
              resolve(null)
            }
          }, reject)
        }
      })
    }

    getAuthUserAndDbUserMerged = async (authUser: any) => {
      return this.user(authUser.uid)
      .get()
      .then(snapshot => {
        const dbUser = snapshot.data();
        if (dbUser) {
          const { roles = {}, username, phoneNumber = '' } = dbUser;
          const authUserAndDbUserMerged = {
            uid: authUser.uid,
            email: authUser.email,
            roles,
            username,
            photoUrl: authUser.photoURL || '',
            phoneNumber
          };
          return authUserAndDbUserMerged
        }
      });
    }

    doSendFeedback = (newFeedback: any) => {
      return this.feedback().add({
        summary: newFeedback.summary,
        details: newFeedback.details,
        createdAt: this.fieldValue.serverTimestamp()
      });
    }

  doAddRequest = (newRequest: requestType) => {
    return this.requests().add({
      location: newRequest.locations,
      numberOfRooms: newRequest.numberOfRooms,
      selfContainedStatus: newRequest.selfContainedStatus,
      moveInDates: newRequest.moveInDates,
      description: newRequest.description,
      priceRange: newRequest.priceRange,
      phoneNumber: newRequest.phoneNumber,
      status: newRequest.status,
      username: newRequest.name,
      createdAt: this.fieldValue.serverTimestamp()
    });
  }

  // TODO: update type to accept user. Merge AuthAndDbUserMerged and HouseType. using any an a short fix
  doAddHouse = (newHouse: any) => {
    return this.houses().add({
      numberOfBedRooms: parseInt(newHouse.numberOfBedRooms, 10),
      numberOfBathRooms: parseInt(newHouse.numberOfBathRooms, 10),
      parkingSpace: parseInt(newHouse.parkingSpace, 10),
      location: {
        url: newHouse.location.url,
        icon: newHouse.location.icon,
        placeId: newHouse.location.placeId,
        formattedAddress: newHouse.location.formattedAddress,
        latitude: newHouse.location.latitude,
        longitude: newHouse.location.longitude
      },
      rentFee: parseInt(newHouse.rentFee, 10),
      description: newHouse.description,
      phoneNumber: newHouse.phoneNumber,
      images: convertMediaFilesObjectsToArray(newHouse.images),
      videos: convertMediaFilesObjectsToArray(newHouse.videos),
      status: HOUSE_STATUS.AVAILABLE,
      selfContainedStatus: newHouse.selfContainedStatus,
      furnishedStatus: newHouse.furnishedStatus,
      currency: newHouse.currency,
      owner: {
        uid: newHouse.user.uid,
        email: newHouse.user.email,
        username: newHouse.user.username,
        phoneNumber: newHouse.user.phoneNumber || ''
      },
      createdAt: this.fieldValue.serverTimestamp()
    });
  }

  getHouses = (houseLimit = HOUSES_BATCH_LIMIT, location = ""): Promise<HouseState[]> => this.loadFirstPage({ location, houseLimit });
  getHousesSearch = (options: optionType): Promise<HouseState[]> =>
    this.searchHouses(options)

  getRelatedHouses = (options: optionType): Promise<HouseState[]> =>
  this.relatedHouses(options)

  onEditHouse = (houseUpdates: House) => {
    const { createdAt, ...rest } = houseUpdates;
    return this.house(houseUpdates.uid).update({
      ...rest,
      updatedAt: this.fieldValue.serverTimestamp()
    });
  }

  onUpdateRequest = (requestUpdates: RequestType) => {
    const { createdAt, ...rest } = requestUpdates;
    return this.request(requestUpdates.uid).update({
      ...rest,
      updatedAt: this.fieldValue.serverTimestamp()
    });
  }

  getHouse = (
    houseId: string
  ) =>
    this.house(houseId).get().then(snapshot => {
      const data = snapshot.data();
      return new Promise((resolve, reject) => {
        if (data) {
          const {
            uid = houseId,
            numberOfBedRooms,
            numberOfBathRooms,
            parkingSpace,
            selfContainedStatus,
            furnishedStatus,
            location,
            status,
            rentFee,
            description,
            phoneNumber,
            numberOfUsersInterested = 0,
            currency,
            images,
            videos,
            owner,
            createdAt,
            updatedAt
          } = data;
          const retrievedHouse: HouseState = {
            uid,
            numberOfBedRooms,
            numberOfBathRooms,
            parkingSpace,
            selfContainedStatus,
            furnishedStatus,
            status,
            location,
            rentFee,
            description,
            phoneNumber,
            images,
            numberOfUsersInterested,
            currency,
            videos,
            owner,
            createdAt: createdAt.toDate().toString(),
            updatedAt: updatedAt ? updatedAt.toDate().toString() : ''
          };
          resolve(retrievedHouse);
        } else {
          reject(new Error('Something went wrong, please try again.'));
        }
      })
    })

  onRequestListener = (
    requestId: string
  ) =>
    this.request(requestId).get().then(snapshot => {
      const data = snapshot.data();
      return new Promise((resolve, reject) => {
        if (data) {
          const {
            uid = requestId,
            location,
            description,
            moveInDates,
            numberOfRooms,
            phoneNumber,
            priceRange,
            selfContainedStatus,
            status,
            username,
            createdAt,
            messages,
            updatedAt
          } = data;
          const retrievedRequest: RequestType = {
            uid,
            location,
            description,
            moveInDates,
            numberOfRooms,
            phoneNumber,
            priceRange,
            selfContainedStatus,
            status,
            username,
            messages,
            createdAt: createdAt.toDate().toString(),
            updatedAt: updatedAt ? updatedAt.toDate().toString() : ''
          };
          resolve(retrievedRequest);
        } else {
          reject(new Error('Something went wrong'))
        }
      })
    })

  doSaveUserInterestInHouse = (
  houseInterest: HouseInterest
  ) => {
    const { houseId, houseImage, rentFee, location, user, houseURL, phoneNumber='', } = houseInterest
    const houseDocRef = this.house(houseId);
    const userDocRef = this.user(user.uid);
    const newHouseUserInterestDoc = this.housesUserInterests().doc();

    return this.db.runTransaction(transaction => {
      return transaction.get(houseDocRef).then(doc => {
        const numberOfUsersInterested =
          doc.data()?.numberOfUsersInterested || 0;

        transaction.update(houseDocRef, {
          numberOfUsersInterested: numberOfUsersInterested + 1
        });
        if (!user.phoneNumber) {
          transaction.update(userDocRef, {
            phoneNumber,
            updatedAt: this.fieldValue.serverTimestamp()
          });
        }
        return transaction.set(
          newHouseUserInterestDoc,
          {
            userInterested: {
              uid: user.uid,
              username: user.username,
              email: user.email,
              phoneNumber: user.phoneNumber || phoneNumber
            },
            house: {
              uid: houseId,
              image: houseImage,
              rentFee,
              location,
              houseURL
            },
            status: HOUSE_INTEREST_STATUS.PENDING,
            createdAt: this.fieldValue.serverTimestamp()
          },
          { merge: true }
        );
      });
    });
  }

  // TODO: This needs to be renamed to user interests. It is also not a listener
  onUserHouseOfInterestListener = (
    {
      userId,
      houseId
    }: {
      userId: string,
      houseId: string
    }
  ) =>
    this.housesUserInterests()
      .where('userInterested.uid', '==', userId)
      .where('house.uid', '==', houseId)
      .where('status', '==', HOUSE_INTEREST_STATUS.PENDING)
      .get().then(snapshot => {
        return new Promise((resolve, reject) => {
          if (snapshot.size) {
            const userHousesOfInterest: UserHouseOfInterest[] = [];
            snapshot.forEach((doc: any) => {
              userHousesOfInterest.push({
                uid: doc.id,
                status: doc.data().status,
                userId: doc.data().userInterested.uid,
                house: doc.data().house
              });
            });
            resolve(userHousesOfInterest);
          } else {
            resolve([]);
          }
        })
      })

    onUserHousesOfInterestListener = (
      userId: string
    ) =>
      this.housesUserInterests()
        .where('userInterested.uid', '==', userId)
        .where('status', '==', HOUSE_INTEREST_STATUS.PENDING)
        .get().then(snapshot => {
          return new Promise((resolve, reject) => {
            if (snapshot.size) {
              const userHousesOfInterest: UserHouseOfInterest[] = [];
              snapshot.forEach((doc: any) => {
                userHousesOfInterest.push({
                  uid: doc.id,
                  status: doc.data().status,
                  userId: doc.data().userInterested.uid,
                  house: doc.data().house
                });
              });
              resolve(userHousesOfInterest);
            } else {
              resolve([]);
            }
          })
        })

  doRemoveUserInterestInHouse = ({ houseId, houseInterestId }: { houseId: string, houseInterestId: string }) => {
    const house = this.house(houseId);
    const houseUserInterestDoc = this.housesUserInterests().doc(
      houseInterestId
    );

    return this.db.runTransaction(transaction => {
      return transaction.get(house).then(doc => {
        const numberOfUsersInterested = doc.data()?.numberOfUsersInterested;

        transaction.update(house, {
          numberOfUsersInterested: numberOfUsersInterested - 1
        });
        return transaction.update(houseUserInterestDoc, {
          status: HOUSE_INTEREST_STATUS.CANCELLED,
          updatedAt: this.fieldValue.serverTimestamp()
        });
      });
    });
  }

  loadFirstPage = ({ location='', houseLimit }: any) => {
    let firstPage = this.houses()
      .orderBy('createdAt', 'desc')
      .limit(houseLimit);
    if (location !== "") {
      firstPage = this.houses()
        .orderBy('createdAt', 'desc')
        .where('location.formattedAddress', '==', location)
        .limit(houseLimit);
    }
    return this.handleHouses(firstPage);
  }

  relatedHouses = (options: any): Promise<HouseState[]> => {    
    const { rentFee } = options;
    const query =  this.houses()
      .where('status', '==', HOUSE_STATUS.AVAILABLE)
      .where('rentFee', '<=', rentFee.greater)
      .where('rentFee', '>=', rentFee.lesser)

    return new Promise((resolve, reject) => {
      query.get().then((documentSnapshots: Snapshot) => {
        if (documentSnapshots.empty) {
          resolve([]);
        }
        const houses = Firebase.computeHousesFromSnapshot(documentSnapshots);
        resolve(houses);
      });
    });
  }

  searchHouses = (options: optionType): Promise<HouseState[]> => {
    const { location, rentFee, rooms } = options;
    const onlyLocation = location && !rentFee && !rooms
    const onlyRentFee = !location && rentFee && !rooms
    const onlyRooms = !location && !rentFee && rooms

    const locationAndRentFee = location && rentFee && !rooms
    const locationAndRooms = location && !rentFee && rooms
    const rentFeeAndRooms = !location && rentFee && rooms

    const locationAndRentFeeAndRooms = location && rentFee && rooms

    let query: any = this.houses().where(
      'status',
      '==',
      HOUSE_STATUS.AVAILABLE
    );

    if(onlyLocation){
      query = query
        .where('location.formattedAddress', '>=', location)
        .where('location.formattedAddress', '<=', location + '\uf8ff')
    }

    // Since we can only perform range comparisons (<, <=, >, >=) on a single field, we are excluding location and Rooms and doing the filter manually in the returned results
    if(onlyRentFee || rentFeeAndRooms || locationAndRentFee || locationAndRentFeeAndRooms){
      if(rentFee.lowerBound && !rentFee.upperBound) {
        query = query
          .where('rentFee', '>=', rentFee.lowerBound)
      }
      if(!rentFee.lowerBound && rentFee.upperBound) {
        query = query
          .where('rentFee', '<=', rentFee.upperBound)
      }
      if(rentFee.lowerBound && rentFee.upperBound) {
        query = query
          .where('rentFee', '>=', rentFee.lowerBound)
          .where('rentFee', '<=', rentFee.upperBound)
      }
    }

    // Since we can only perform range comparisons (<, <=, >, >=) on a single field, we are excluding location and doing the filter manually in the returned results
    if(onlyRooms || locationAndRooms){
      query = query
        .where('numberOfBedRooms', rooms.comparisonSign, rooms.numberOfBedRooms)
    }

    return new Promise((resolve, reject) => {
      query.get().then((documentSnapshots: Snapshot) => {
        if (documentSnapshots.empty) {
          resolve([]);
        }
        const houses = Firebase.computeHousesFromSnapshot(documentSnapshots);
        if(locationAndRentFee || locationAndRooms) {
          const filteredHouses = houses.filter(house => house.location.formattedAddress?.includes(location))
          resolve(filteredHouses);
        } else if(rentFeeAndRooms) {
          const filteredHouses = houses.filter(house => {
            if(rooms!.comparisonSign === '==') {
              return house.numberOfBedRooms === rooms!.numberOfBedRooms
            }
            return house.numberOfBedRooms >= rooms!.numberOfBedRooms
          })
          resolve(filteredHouses);
        } else if(locationAndRentFeeAndRooms) {
          const filteredHouses = houses.filter(house => {
            if(rooms!.comparisonSign === '==') {
              return (house.numberOfBedRooms === rooms!.numberOfBedRooms && house.location.formattedAddress?.includes(location))
            }
            return (house.numberOfBedRooms >= rooms!.numberOfBedRooms && house.location.formattedAddress?.includes(location))
          })
          resolve(filteredHouses);
        }
        else {
          resolve(houses);
        }
      });
    });
  }

  loadMore = (): Promise<HouseState[]> => {
    return new Promise((resolve, reject) => {
      if (this.endOfPagination) {
        resolve([]);
      }
      this.handleHouses(this.housesNext).then((houses: HouseState[]) => {
        this.endOfPagination = false;
        if (!houses.length || houses.length < HOUSES_BATCH_LIMIT) {
          this.endOfPagination = true;
        }
        resolve(houses);
      });
    });
  }

  handleHouses = (
    ref: app.firestore.Query<app.firestore.DocumentData>
  ): Promise<HouseState[]> => {
    return new Promise((resolve, reject) => {
      if (!ref) {
        resolve([]);
      }
      ref
        .where('status', 'in', [HOUSE_STATUS.AVAILABLE, HOUSE_STATUS.TAKEN])
        .get()
        .then(documentSnapshots => {
          if (documentSnapshots.empty) {
            this.endOfPagination = true;
            resolve([]);
          }
          const houses = Firebase.computeHousesFromSnapshot(documentSnapshots);

          /* Build reference for next batch */
          const lastVisible =
            documentSnapshots.docs[documentSnapshots.size - 1];
          if (!lastVisible) {
            return;
          }
          this.housesNext = this.houses()
            .orderBy('createdAt', 'desc')
            .startAfter(lastVisible)
            .limit(HOUSES_BATCH_LIMIT);
          resolve(houses);
        });
    });
  }

  getUserHouses = (
    userId: string,
    withHouses: (houses: HouseState[]) => void,
    withEmptyHouses: () => void
  ) =>
    this.houses()
      .orderBy('createdAt', 'desc')
      .where('owner.uid', '==', userId)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          const houses = Firebase.computeHousesFromSnapshot(snapshot);
          withHouses(houses);
        } else {
          withEmptyHouses();
        }
      })

  // Getting only the last 4 available houses added
  getRecentHouses = async () => {
    return this.houses()
    .orderBy('createdAt', 'desc')
    .where('status', '==', HOUSE_STATUS.AVAILABLE)
    .limit(4)
    .get().then(snapshot => {
      return new Promise((resolve, reject) => {
        if (snapshot.size) {
          const houses = Firebase.computeHousesFromSnapshot(snapshot);
          resolve(houses);
        } else {
          resolve([]);
        }
      })
    })
  }

  // No longer a listener. Its name can be updated. - The sam for other listeners.
  onRequestsListener = async () => {
    return this.requests()
    .orderBy('createdAt', 'desc')
    .get().then(snapshot => {
      return new Promise((resolve, reject) => {
        if (snapshot.size) {
          const requests = Firebase.computeRequestFromSnapshot(snapshot);
          resolve(requests);
        } else {
          resolve([]);
        }
      })
    })
  }

  user = (uid: string) => this.db.doc(`users/${uid}`);
  users = () => this.db.collection('users');

  image = (name: string) => this.storage.ref(`/images/${name}`);
  thumbnails = (name: string) => this.storage.ref(`/images/thumbs/${name}`);
  images = () => this.storage.ref('images');
  video = (name: string) => this.storage.ref(`/videos/${name}`);
  videos = () => this.storage.ref('videos');

  house = (uid?: string) => this.db.doc(`houses/${uid}`);
  houses = () => this.db.collection('houses');
  requests = () => this.db.collection('requests');
  feedback = () => this.db.collection('feedback');
  request = (uid?: string) => this.db.doc(`requests/${uid}`);

  housesUserInterest = (uid: string) =>
    this.db.doc(`houseUserInterests/${uid}`)
  housesUserInterests = () => this.db.collection('houseUserInterests');

  private static instance: Firebase;

  private constructor() {
    app.initializeApp(config);

    this.fieldValue = app.firestore.FieldValue;

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();

    this.housesNext = null;
    this.endOfPagination = false;
  }
}

export default Firebase;

export type AuthUser = AuthAndDbUserMerged;
