import * as Cookies from 'js-cookie';
import { MediaType, MediaTypeForArrays } from '../Houses/house.types';

export const setCookie = (name: string, value: string, expires?: number): void => {
  Cookies.remove(name);
  Cookies.set(name, value, { expires: expires ?? 30 });
};

export const getCookie: any = (name: string) => {
  const cookie = Cookies.get(name);

  if (cookie === undefined) {
    return null;
  } else {
    return cookie;
  }
};

export const removeCookie = (name: string): void => Cookies.remove(name);

export const convertMediaFilesObjectsToArray = (objectToConvert: MediaType): MediaTypeForArrays[] =>
  Object.keys(objectToConvert).length
    ? Object.entries(objectToConvert).map(([ key, value ]) => ({ ...value, id: key }))
    : [];

export const formatedFees = (fee: any) => fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
