// @ts-nocheck

import * as React from 'react';
// tslint:disable-next-line: no-submodule-imports
import 'react-phone-number-input/style.css';
import './styles.css';
import PhoneInput from 'react-phone-number-input';
import { v4 as uuidv4 } from 'uuid';
import { Container, Button, Form, Card, Badge, Spinner } from 'react-bootstrap';
// tslint:disable-next-line: no-submodule-imports
import { FaCheck } from 'react-icons/fa';
// tslint:disable-next-line: no-submodule-imports
import Feedback from 'react-bootstrap/Feedback';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';

import {
  SubmitType,
  HouseType,
  AddHouseStep1Props,
  AddHouseStep2Props,
  AddHouseFormProps
} from './house.types';
import { convertMediaFilesObjectsToArray } from '../util';
import { addHouseFormSchema, step1Schema, step2Schema } from './house.schema';
import { Loading } from './HouseLoader';
import * as phoneNumberUtils from '../PhoneNumber';
import { FirebaseContext } from '../firebase';
import {
  INITIAL_STATE,
  DESCRIBE_MAX_CHAR_COUNT,
  INTERNET_CONNECTION_ERROR
} from './house.constants';
import { ValidationError } from 'yup';
import { useForm } from '../Form';
import { selectAuthUser } from '../Auth/authSlice';
import { useAddHouseMutation } from '../api/apiSlice';
import * as ROUTES from '../constants/routes';

const FilesLoader = () => (
  <Button
    style={{
      backgroundColor: '#3A8D8C',
      color: 'white',
      marginBottom: '0.3125rem'
    }}
  >
    <Spinner animation="border" variant="light" size="sm" />
    Loading...
  </Button>
);

const Step1: React.FC<AddHouseStep1Props> = ({
  inputs,
  handleChange,
  nextStep,
  handleBlur,
  errors,
  touched,
  handlePhoneNumberChange,
  handlePhoneNumberBlur
}) => (
  <>
    <Form.Group controlId="numberOfBedRooms">
      <Form.Label>Number of bed rooms</Form.Label>
      <Form.Control
        type="number"
        min="1"
        placeholder="Enter number of bed rooms"
        onChange={handleChange}
        name="numberOfBedRooms"
        value={inputs.numberOfBedRooms}
        onBlur={handleBlur}
        isInvalid={!!errors.numberOfBedRooms}
        isValid={touched.numberOfBedRooms && !errors.numberOfBedRooms}
      />
      <Form.Control.Feedback />
      <Form.Control.Feedback type="invalid">
        {errors.numberOfBedRooms}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="numberOfBathRooms">
      <Form.Label>Number of bath rooms</Form.Label>
      <Form.Control
        type="number"
        min="1"
        placeholder="Enter number of bath rooms"
        onChange={handleChange}
        name="numberOfBathRooms"
        value={inputs.numberOfBathRooms}
        onBlur={handleBlur}
        isInvalid={!!errors.numberOfBathRooms}
        isValid={touched.numberOfBathRooms && !errors.numberOfBathRooms}
      />
      <Form.Control.Feedback />
      <Form.Control.Feedback type="invalid">
        {errors.numberOfBathRooms}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="parkingSpace">
      <Form.Label>Parking space</Form.Label>
      <Form.Control
        type="number"
        min="0"
        placeholder="Enter number of personal cars"
        onChange={handleChange}
        name="parkingSpace"
        value={inputs.parkingSpace}
        onBlur={handleBlur}
        isInvalid={!!errors.parkingSpace}
        isValid={touched.parkingSpace && !errors.parkingSpace}
      />
      <Form.Control.Feedback />
      <Form.Control.Feedback type="invalid">
        {errors.parkingSpace}
      </Form.Control.Feedback>
      <Form.Text className="text-muted">
        Enter the number of personal cars that can use the available parking
        space allocated to the house
      </Form.Text>
    </Form.Group>
    <Form.Group controlId="selfContainedStatus">
      <Form.Check
        type="checkbox"
        onChange={handleChange}
        name="selfContainedStatus"
        value={inputs.selfContainedStatus}
        label="Self contained"
      />
    </Form.Group>
    <Form.Group controlId="rentFee">
      <Form.Label className='d-flex'>Monthly Rent fee
        <div className='ml-3'>
          <Form.Check
            type="radio"
            inline
            onChange={handleChange}
            name="currency"
            value="ugx"
            label="UGX (Shs)"
          />
          <Form.Check
            type="radio"
            onChange={handleChange}
            name="currency"
            value="usd"
            label="USD ($)"
            inline
          />
        </div>
      </Form.Label>
      <Form.Control
        type="number"
        placeholder="e.g 500000"
        onChange={handleChange}
        name="rentFee"
        value={inputs.rentFee}
        onBlur={handleBlur}
        isInvalid={!!errors.rentFee}
        isValid={touched.rentFee && !errors.rentFee}
      />
      <Form.Control.Feedback />
      <Form.Control.Feedback type="invalid">
        {errors.rentFee}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="phoneNumber">
      <Form.Label>Contact Phone number</Form.Label>
      <Form.Control
        type="tel"
        placeholder="Enter phone number"
        as={PhoneInput}
        name="phoneNumber"
        countrySelectProps={{ unicodeFlags: true }}
        defaultCountry="UG"
        onChange={handlePhoneNumberChange}
        onBlur={handlePhoneNumberBlur}
        value={inputs.phoneNumber}
        isInvalid={!!errors.phoneNumber}
        isValid={touched.phoneNumber && !errors.phoneNumber}
      />
      <Form.Control.Feedback />
      <Form.Control.Feedback type="invalid">
        {errors.phoneNumber}
      </Form.Control.Feedback>
      <Form.Text className="text-muted">
        We'll never share your phone number with anyone else
      </Form.Text>
    </Form.Group>
    <Button
      style={{
        backgroundColor: '#3A8D8C',
        borderColor: '#3A8D8C',
        float: 'right'
      }}
      onClick={nextStep}
    >
      Next
    </Button>
  </>
);

const Step2: React.FC<AddHouseStep2Props> = ({
  inputs,
  handleChange,
  prevStep,
  handleBlur,
  errors,
  touched,
  isLoading,
  setErrors,
  setInputs,
  msg,
  err
}) => {
  const [descriptionCharsLeft, setDescriptionCharsLeft] = React.useState(
    DESCRIBE_MAX_CHAR_COUNT
  );
  const firebase = React.useContext(FirebaseContext);
  const houseImages = convertMediaFilesObjectsToArray(inputs.images);
  const houseVideos = convertMediaFilesObjectsToArray(inputs.videos);

  const MAXIMUM_FILE_NAME_LENGTH = 10;

  const handleMediaAsFile = (event: React.FormEvent) => {
    event.persist();
    const target = event.target as HTMLInputElement;
    const fileList = target.files as FileList;

    const files = Array.from(fileList).map(file => file);
    const targetName = target.name;
    const allowedVideoFormats = [
      'video/mp4',
      'video/ogv',
      'video/webm',
      'video/mov',
      'video/mkv'
    ];
    const allowedImageFormats = ['image/png', 'image/jpg', 'image/jpeg'];
    const maxImageSize = 3 * 1024 * 1024; // 3MB
    const maxVideoSize = 10 * 1024 * 1024; // 10MB

    const largeFileSize = (file: File) => file.size > maxImageSize;
    const containsInvalidFileType = (file: File) =>
      !allowedImageFormats.includes(file.type);

    if (targetName === 'image') {
      if (files.some(largeFileSize)) {
        setErrors({
          ...errors,
          image: 'Large file(s), expects at most 3 MB files'
        });
        return;
      }
      if (files.some(containsInvalidFileType)) {
        setErrors({
          ...errors,
          image: 'Only .png, .jpg and .jpeg formats allowed'
        });
        return;
      }
    } else {
      // Since we are only uploading one vide file, we can get it directly without a looping;
      const videoFile = files[0];
      if (videoFile.size > maxVideoSize) {
        setErrors({
          ...errors,
          video: 'Large file, expects at most 10 MB files'
        });
        return;
      }
      if (!allowedVideoFormats.includes(videoFile.type)) {
        setErrors({
          ...errors,
          video: 'Only .mp4, .ogv, .webm, .mov and .mkv formats allowed'
        });
        return;
      }
    }

    if ((targetName === 'image' || targetName === 'video') && files.length) {
      let mediaCount = files.length;
      setInputs((inputs: any) => ({
        ...inputs,
        [`${targetName}sUploading`]: true
      }));
      files.forEach(file => {
        const fileId = uuidv4();
        const filename = `${fileId}${file.name}`;
        const uploadTask = firebase?.[targetName](filename).put(file);

        uploadTask?.on(
          'state_changed',
          () => {},
          err => {
            console.log(err);
          },
          async () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              const thumbUrl = downloadURL.replace(/\.(?=[^.]*$)/, '_400x400.');
              const mediaType = targetName === 'image' ? 'images' : 'videos';
              mediaCount--;
              setInputs((inputs: { [x: string]: any; image: any }) => ({
                ...inputs,
                [mediaType]: {
                  ...inputs[mediaType],
                  [fileId]: {
                    name: filename,
                    url: downloadURL,
                    thumbnail: thumbUrl,
                    isSelected: true,
                    isActive: true
                  }
                },
                [`${targetName}sUploading`]: mediaCount === 0 ? false : true,
                image: targetName === 'image' ? true : inputs.image
              }));

              if (mediaType === 'images') {
                setErrors((errors: any) => ({ ...errors, image: null }));
              }
            });
          }
        );
      });
    }
  };

  const handleDeleteFile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.persist();
    const mediaType = event.currentTarget.dataset.type;
    const fileToDelete = event.currentTarget.dataset.filename;
    const fileId = event.currentTarget.dataset.id;
    const target = mediaType === 'images' ? 'images' : 'videos';
    setInputs((inputs: any) => ({ ...inputs, [`${target}Uploading`]: true }));
    if (fileToDelete && fileId) {
      firebase?.[target]()
        .child(fileToDelete)
        .delete()
        .then(() => {
          const { [fileId]: id, ...rest } = inputs[target];
          const oneImage =
            mediaType === 'images' && Object.keys(inputs.images).length === 1;
          setInputs((inputs: { image: any }) => ({
            ...inputs,
            [target]: rest,
            [`${target}Uploading`]: false,
            image: oneImage ? false : inputs.image
          }));
          if (oneImage) {
            setErrors((errors: any) => ({
              ...errors,
              image: 'Image(s) are required'
            }));
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleLocationChange = (event: React.FormEvent) => {
    event.persist();
    const target = event.target as HTMLInputElement;
    const option = {
      componentRestrictions: { country: 'ug' }
    };

    setInputs((inputs: any) => ({
      ...inputs,
      location: { formattedAddress: target.value }
    }));

    try {
      const autoComplete = new google.maps.places.Autocomplete(target, option);
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        const latitude = place.geometry?.location.lat(); // latitude in degrees
        const longitude = place.geometry?.location.lng(); // longitude in degrees

        // replacing the first values in formatted address with name for consistency. Some locations have place name different from the value in formatted address.
        const formattedAddress = place.formatted_address?.replace(place.formatted_address?.split(',')[0], place.name)

        setInputs((inputs: any) => ({
          ...inputs,
          location: {
            url: place.url,
            icon: place.icon,
            formattedAddress: formattedAddress,
            placeId: place.place_id,
            latitude,
            longitude
          },
          locationName: formattedAddress
        }));
        setErrors((errors: any) => ({ ...errors, location: null }));
      });
    } catch (e) {
      setErrors((errors: any) => ({
        ...errors,
        location: INTERNET_CONNECTION_ERROR
      }));
    }
  };

  const handleDescriptionChange = (event: React.FormEvent) => {
    handleChange(event);

    const target = event.target as HTMLInputElement;
    const count = target.value.length;
    const charsLeft = DESCRIBE_MAX_CHAR_COUNT - count;
    setDescriptionCharsLeft(charsLeft);
  };

  return (
    <>
      <Form.Group controlId="location">
        <Form.Label>Location</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter name of the place"
          onChange={handleLocationChange}
          name="location"
          onBlur={handleBlur}
          isInvalid={!!errors.location}
          isValid={touched.location && !errors.location}
        />
        <Form.Control.Feedback />
        <Form.Control.Feedback type="invalid">
          {errors.location}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="furnishedStatus">
        <Form.Check
          type="checkbox"
          onChange={handleChange}
          name="furnishedStatus"
          value={inputs.furnishedStatus}
          label="Furnished"
        />
      </Form.Group>
      <Form.Group controlId="description">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={6}
          placeholder={`Enter a simple house description. Maximum (${DESCRIBE_MAX_CHAR_COUNT}) characters`}
          onChange={handleDescriptionChange}
          name="description"
          value={inputs.description}
          maxLength={DESCRIBE_MAX_CHAR_COUNT}
          onBlur={handleBlur}
          isInvalid={!!errors.description}
          isValid={touched.description && !errors.description}
        />
        <Form.Control.Feedback />
        <Form.Control.Feedback type="invalid">
          {errors.description}
        </Form.Control.Feedback>
        <Form.Text className="text-muted">
          Characters left ({descriptionCharsLeft})
        </Form.Text>
      </Form.Group>
      {inputs.imagesUploading && <FilesLoader />}
      {!inputs.imagesUploading && (
        <Form.Group>
          <Form.File custom={true}>
            <Form.File.Label>
              Choose house image(s){' '}
              {touched.image && !errors.image ? (
                <FaCheck style={{ color: 'green' }} />
              ) : null}
            </Form.File.Label>
            <Form.File.Input
              isInvalid={!!errors.image && !inputs.imagesUploading}
              id="image"
              name="image"
              onChange={handleMediaAsFile}
              title=" "
              isValid={touched.image && !errors.image}
              multiple={true}
              onBlur={handleBlur}
            />
            <Feedback type="invalid">{errors.image}</Feedback>
          </Form.File>
        </Form.Group>
      )}
      {houseImages.length ? (
        <div className='row'>
          {houseImages.map(image => (
            <div className='col-sm-3 col-6 mb-1' key={image.id} style={{ cursor: 'pointer' }}>
              <img src={image.url} height="100px" width="100px" alt={image.name}/>
              <Badge
                pill={true}
                data-type="images"
                data-filename={image.name}
                data-id={image.id}
                onClick={handleDeleteFile}
                variant="secondary"
              >
                {image.name.length > MAXIMUM_FILE_NAME_LENGTH
                  ? `...${image.name.slice(-MAXIMUM_FILE_NAME_LENGTH)}`
                  : image.name}{' '}
                x
              </Badge>{' '}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {inputs.videosUploading && (
        <p>
          <FilesLoader />
        </p>
      )}
      {!inputs.videosUploading && (
        <Form.Group>
          <Form.File custom={true}>
            <Form.File.Label>Choose a house video (Optional)</Form.File.Label>
            <Form.File.Input
              isInvalid={!!errors.video && !inputs.videosUploading}
              id="video"
              name="video"
              onChange={handleMediaAsFile}
              value=""
              title=" "
            />
            <Feedback type="invalid">{errors.video}</Feedback>
          </Form.File>
        </Form.Group>
      )}
      {houseVideos.length ? (
        <>
          {houseVideos.map(video => (
            <div key={video.id} style={{ cursor: 'pointer' }}>
              <Badge
                pill={true}
                data-type="videos"
                data-filename={video.name}
                data-id={video.id}
                onClick={handleDeleteFile}
                variant="secondary"
              >
                {video.name.length > MAXIMUM_FILE_NAME_LENGTH
                  ? `...${video.name.slice(-MAXIMUM_FILE_NAME_LENGTH)}`
                  : video.name}{' '}
                x
              </Badge>{' '}
            </div>
          ))}
        </>
      ) : (
        ''
      )}
      {err ? <p className="text-center text-danger">{msg}</p> : null}
      <Button onClick={prevStep} className="btn btn-secondary" type="button">
        Back
      </Button>
      <Button
        type="submit"
        style={{
          backgroundColor: '#3A8D8C',
          borderColor: '#3A8D8C',
          float: 'right'
        }}
        disabled={isLoading || inputs.imagesUploading || inputs.videosUploading}
      >
        Submit
      </Button>
      {isLoading && <Loading />}
    </>
  );
};

const AddHouseForm: React.FC<AddHouseFormProps> = ({
  inputs,
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  isLoading,
  setErrors,
  setInputs,
  err,
  msg
}) => {
  const [step, setStep] = React.useState(1);

  const validateStep1 = () => {
    return new Promise((resolve, reject) => {
      step1Schema
        .validate(
          {
            numberOfBedRooms: inputs.numberOfBedRooms,
            numberOfBathRooms: inputs.numberOfBathRooms,
            rentFee: inputs.rentFee,
            parkingSpace: inputs.parkingSpace,
            phoneNumber: inputs.phoneNumber,
            currency: inputs.currency
          },
          { abortEarly: false }
        )
        .then(() => resolve())
        .catch(e => {
          const returnedErrors: { [key: string]: string } = {};
          e.inner.forEach((error: ValidationError) => {
            returnedErrors[error.path] = error.message;
          });
          setErrors({ ...errors, ...returnedErrors });
          reject();
        });
    });
  };

  const nextStep = () => {
    validateStep1().then(() => {
      setStep(step => step + 1);
    });
  };

  const prevStep = () => {
    setStep(step => step - 1);
  };

  const handlePhoneNumberChange = (value: string) => {
    phoneNumberUtils.handlePhoneNumberChange(handleChange, value);
  };

  const handlePhoneNumberBlur = (value: any) => {
    phoneNumberUtils.handlePhoneNumberBlur(handleBlur);
  };

  const showStep = () => {
    if (step === 1) {
      return (
        <Step1
          inputs={inputs}
          handleChange={handleChange}
          nextStep={nextStep}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          handlePhoneNumberChange={handlePhoneNumberChange}
          handlePhoneNumberBlur={handlePhoneNumberBlur}
        />
      );
    }

    if (step === 2) {
      return (
        <Step2
          inputs={inputs}
          handleChange={handleChange}
          prevStep={prevStep}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          isLoading={isLoading}
          setErrors={setErrors}
          setInputs={setInputs}
          msg={msg}
          err={err}
        />
      );
    }
  };

  return (
    <Container style={{ padding: '1em' }}>
      <div className="col-md-6 mb-md-5 offset-md-3 new-house-form">
        <Card className="shadow rounded">
          <Card.Header
            as="h3"
            style={{ color: '#3A8D8C' }}
            className="text-center bg-white"
          >
            Add House
          </Card.Header>
          <Card.Body>
            <Card.Title className="text-center">Step {step} of 2</Card.Title>
            <Form onSubmit={handleSubmit}>{showStep()}</Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

const AddHouse = () => {
  const {
    inputs,
    handleChange,
    handleBlur,
    errors,
    touched,
    setInputs,
    setErrors
  } = useForm(INITIAL_STATE, addHouseFormSchema);
  const user = useSelector(selectAuthUser);
  const history = useHistory()
  const [addHouse, { isLoading, isError, error, isSuccess }] = useAddHouseMutation()

  const validateStep2 = () => {
    return new Promise((resolve, reject) => {
      step2Schema
        .validate(
          {
            description: inputs.description,
            image: inputs.image,
            location: inputs.location
          },
          { abortEarly: false }
        )
        .then(() => resolve())
        .catch(e => {
          const returnedErrors: { [key: string]: string } = {};
          e.inner.forEach((error: ValidationError) => {
            /*
             * Nested errors on location have a path like "location.url"
             * Since they all have the same value, they are being assigned to the same key.
             * It also makes the display of errors easier on the ui.
             */
            const pathName = inputs.hasOwnProperty(error.path)
              ? error.path
              : 'location';
            returnedErrors[pathName] = error.message;
          });
          setErrors({ ...errors, ...returnedErrors });
          reject();
        });
    });
  };

  const handleSubmit = (event: SubmitType) => {
    event.preventDefault();
    validateStep2().then(async () => {
      const newHouse: HouseType = { ...inputs };
      const id = await addHouse({ ...newHouse, user }).unwrap();
      history.push(`${ROUTES.HOUSES}/${id}`)
    });
  };

  const canSafelyNavigate = (): boolean => {
    const initialValuesStr = JSON.stringify(INITIAL_STATE);
    const inputsStr = JSON.stringify(inputs);

    if (initialValuesStr === inputsStr || isSuccess) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (!canSafelyNavigate()) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
    };
  });

  return (
    <>
      <Prompt
        when={!canSafelyNavigate()}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <AddHouseForm
        inputs={inputs}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        isLoading={isLoading}
        setErrors={setErrors}
        setInputs={setInputs}
        err={isError}
        msg={error}
      />
    </>
  );
};

export default AddHouse;
