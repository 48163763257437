/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useEffect } from 'react';

import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import {
  Container,
  Col,
  Popover,
  Overlay,
  Nav,
  Navbar,
  Button
} from 'react-bootstrap';

import * as ROUTES from '../constants/routes';
import SignOutButton from '../Auth/SignOut';
import { useSelector } from 'react-redux';
import {
  selectIsLoggedIn,
  selectProfilePic,
  selectIsAdmin
} from '../Auth/authSlice';
import avatar from '../asserts/avatar.png';

import classNames from 'classnames';
import { NavAuthButton } from './NavAuthButton';

// TODO: These styles can be improved. Considered brevity when moving them from scss files
const navCss = {
  self: css({
    '.navbar-light .navbar-nav .nav-link': {
      color: '#202223'
    },
    '@media (min-width: 768px)': {
      '.navbar-expand-lg .navbar-nav .nav-link': {
        paddingRight: '1.5rem',
        paddingLeft: 0
      },
      '.how-it-works-button': {
        padding: '0 1.5rem 0'
      }
    },
    '@media only screen and (max-width: 991px)': {
      '.large-screen': {
        display: 'none'
      },
      '.navbar-light .navbar-nav .nav-link': {
        color: 'rgb(255, 255, 255)',
        paddingLeft: '1em'
      }
    },
    '@media only screen and (min-width: 992px)': {
      '.small-medium-screen': {
        display: 'none'
      }
    },
    '@media only screen and (min-width: 1025px) and (max-width: 1199px)': {
      '.large-screen': {
        width: '4.688rem'
      }
    },
    '& a:link': {
      textDecoration: 'none'
    }
  }),
  siteName: css({
    fontSize: 'x-large',
    color: '#00a69c',
    width: 'fit-content',
    '& a': {
      color: '#00a69c',
      '&:hover': {
        color: '#00a69c'
      }
    },
    '@media (min-width: 768px)': {
      marginLeft: '-35px',
      '&.houses-route': {
        marginLeft: '-52px'
      }
    }
  }),
  navRequestButton: css({
    minHeight: '2.625rem',
    padding: '0.25rem 1.5rem 0.375rem',
    borderRadius: '0.25rem',
    backgroundColor: '#00a69c',
    borderColor: '#00a69c',
    marginRight: '1rem',
    '&:hover, &:focus, &:active, &:visited': {
      backgroundColor: '#00a69c',
      borderColor: '#00a69c'
    }
  }),
  pageHeader: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    height: '4rem',
    '& #responsive-navbar-nav': {
      justifyContent: 'flex-end',
      '&.navbar-collapse': {
        '@media (max-width: 768px) and (max-width: 991px)': {
          backgroundColor: '#3a8d8c'
        }
      }
    },
    '& .home': {
      '& a': {
        fontSize: '14px',
        color: 'rgb(51, 51, 51)',
        fontWeight: 500,
        '&:hover': {
          color: '#4dc1df'
        }
      },
      '&.row': {
        flexWrap: 'nowrap'
      },
      '@media (max-width: 768px)': {
        '&.row': {
          marginLeft: '5px'
        },
        '& a': {
          color: '#ffffff'
        }
      },
      '@media (max-width: 991px)': {
        '& a': {
          color: '#ffffff'
        }
      }
    }
  }),
  rentalsLogo: css({
    height: '2.5rem',
    width: '4.6rem',
    marginBottom: '0.5rem'
  }),
  rentalsLogoText: css({
    paddingLeft: '0.2rem',
    marginLeft: '-2rem'
  }),
  navbarToggler: {
    border: '0',
    padding: '0.3em',
    fontSize: '1rem',
    '&:focus': {
      outline: 'none'
    }
  },
  navbarCollapse1: css({
    justifyContent: 'flex-end'
  }),
  navBarCollapse2: css({
    '@media (max-width: 768px)': {
      position: 'absolute',
      width: '100%',
      top: '0px',
      marginTop: '4rem'
    },
    '@media (max-width: 600px) and (max-width: 768px)': {
      position: 'absolute',
      width: '100%',
      top: '0px',
      marginTop: '4rem'
    }
  }),
  signButton: css({
    '@media (max-width: 768px)': {
      paddingLeft: '1px',
      paddingTop: '1rem',
      paddingBottom: '1.2rem'
    },
    '@media (max-width: 991px)': {
      paddingLeft: '1px',
      paddingTop: '1rem',
      paddingBottom: '1.2rem'
    },
    '@media (max-width: 600px) and (max-width: 768px': {
      paddingLeft: '1px',
      paddingTop: '1rem',
      paddingBottom: '1.2rem'
    }
  }),
  largeScreen: {
    avatar: css({
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'ellipsis',
      lineHeight: 'normal',
      position: 'relative',
      '& img': {
        width: '32px',
        height: '32px',
        display: 'inline-block',
        verticalAlign: 'middle',
        WebkitBorderRadius: '100%',
        borderRadius: '100%'
      }
    }),
    ul: css({
      padding: '0',
      listStyle: 'none',
      '& li.list-item': {
        textAlign: 'left',
        width: '100%',
        lineHeight: '1.4',
        whiteSpace: 'nowrap',
        listStyle: 'none',
        fontSize: '18px',
        fontWeight: 300,
        '& .item-link': {
          padding: '7px 25px',
          display: 'inline-block',
          position: 'relative',
          fontSize: '16px',
          textDecoration: 'none',
          cursor: 'pointer',
          userSelect: 'none',
          letterSpacing: '0',
          fontWeight: 400,
          fontStyle: 'normal',
          boxSizing: 'border-box',
          background: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0.6)',
          color: 'rgba(0, 0, 0, 0.6)',
          WebkitTapHighlightColor: 'transparent'
        }
      }
    })
  }
};

const aboutNavCss = {
  self: css({
    width: 'auto',
    margin: '0 0 1rem',
    backgroundImage:
      'url(https://res.cloudinary.com/dcsrepenv/image/upload/q_auto,f_auto/v1596436133/rentals/about-landing.png)',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#f7f7f7',
    backgroundSize: '100% 100%, cover',
    height: '31.25rem',
    padding: '1rem 5rem 3rem 5rem',
    '& .about a': {
      fontSize: 'large',
      color: '#ffffff !important', // remember to remove important
      padding: '0 0 5rem 0'
    },
    '@media (max-width: 600px) and (max-width: 768px)': {
      padding: '1rem 0rem 3rem 1rem',
      nav: {
        flexWrap: 'wrap',
        padding: '0',
        marginBottom: '4rem'
      },
      '& .about a': {
        padding: '0 0 0.5rem 0'
      },
      '& .about.row': {
        padding: '1rem',
        marginLeft: '1rem'
      }
    },
    '& .navbar-collapse': {
      '@media (max-width: 768px) and (max-width: 991px)': {
        backgroundColor: '#3a8d8c'
      }
    }
  }),
  siteNameAbout: css({
    fontSize: 'x-large',
    color: '#ffffff',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: 'normal',
    padding: '0 0 5rem 0',
    '& a': {
      color: '#ffffff'
    },
    '@media (max-width: 600px) and (max-width: 768px)': {
      padding: 0,
      display: 'contents'
    }
  }),
  heroText: css({
    height: '13rem',
    fontSize: '3.625rem',
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: '#ffffff'
  })
};

const hamMenuIconCss = css({
  cursor: 'pointer',
  WebkitTapHighlighColor: 'transparent',
  transition: 'transform 400ms',
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  '&.active': {
    transform: 'rotate(45deg)'
  },
  '& path.line': {
    fill: 'none',
    transition: 'stroke-dasharray 400ms, stroke-dashoffset 400ms',
    stroke: '#000',
    strokeWidth: '5.5',
    strokeLinecap: 'round'
  },
  '&.ham8': {
    '& .top': {
      strokeDasharray: '40 160'
    },
    '& .middle': {
      strokeDasharray: '40 142',
      transformOrigin: '50%',
      transition: 'transform 400ms'
    },
    '& .bottom': {
      strokeDasharray: '40 85',
      transformOrigin: '50%',
      transition: 'transform 400ms, stroke-dashoffset 400ms'
    },
    '&.active': {
      '& .top': {
        strokeDashoffset: '-64px'
      },
      '& .middle': {
        transform: 'rotate(90deg)'
      },
      '& .bottom': {
        strokeDashoffset: '-64px'
      }
    }
  },
  '&.nav-toggle-icon': {
    width: '2.5em',
    height: '2.5em',
    verticalAlign: 'middle'
  }
});

type NavPropsType = {
  isLoggedIn: boolean;
  isAdmin: boolean;
  showPopover: (event: React.FormEvent) => void;
  hidePopover: (event: any) => void;
  ref: React.MutableRefObject<null>;
  profilePic: string;
  show: boolean;
  target: any;
  iconToggled: boolean;
  toggleHamburgerIcon: () => void;
  handleRequestClick: () => void;
  handleAddHouseClick: () => void;
};

type HamMenuIconTypes = {
  iconToggled: boolean;
  toggleHamburgerIcon: () => void;
};

const HamMenuIcon: React.FC<HamMenuIconTypes> = ({
  iconToggled,
  toggleHamburgerIcon
}) => (
  <svg
    className={`ham hamRotate ham8 nav-toggle-icon ${
      iconToggled ? 'active' : ''
    }`}
    viewBox="0 0 100 100"
    width="80"
    onClick={toggleHamburgerIcon}
    css={hamMenuIconCss}
  >
    <path
      className="line top"
      d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
    />
    <path className="line middle" d="m 30,50 h 40" />
    <path
      className="line bottom"
      d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
    />
  </svg>
);
const Navigation = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAdmin = useSelector(selectIsAdmin);
  const profilePic = useSelector(selectProfilePic);
  const [show, setShow] = React.useState(false);
  const [iconToggled, setIconToggled] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [scrolled, setScrolled] = React.useState(false);
  const ref = React.useRef(null);
  const currentLocation = useLocation();
  const matchesHousesRoute = !!useRouteMatch('/houses');

  const showPopover = (event: any) => {
    setShow(true);
    setTarget(event.target);
  };

  const hidePopover = (event: any) => {
    setShow(false);
    setTarget(event.target);
  };

  const toggleHamburgerIcon = () => {
    setIconToggled(!iconToggled);
  };

  const handleRequestClick = () => {
    toggleHamburgerIcon();
  };

  const handleAddHouseClick = () => {
    toggleHamburgerIcon();
  };

  useEffect(() => {
    return () => {
      if (show) {
        setShow(false);
      }
    };
  });

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  return (
    <div css={navCss.self}>
      {currentLocation.pathname === '/about' ? (
        <AboutNav
          showPopover={showPopover}
          isAdmin={isAdmin}
          isLoggedIn={isLoggedIn}
          ref={ref}
          hidePopover={hidePopover}
          profilePic={profilePic}
          show={show}
          target={target}
          iconToggled={iconToggled}
          toggleHamburgerIcon={toggleHamburgerIcon}
          handleRequestClick={handleRequestClick}
          handleAddHouseClick={handleAddHouseClick}
        />
      ) : (
        <Navbar
          collapseOnSelect={true}
          expand="lg"
          className={
            scrolled
              ? ' align-items-center site-header fixed-top nav-shadow'
              : 'align-items-center site-header fixed-top'
          }
          css={navCss.pageHeader}
        >
          <Container>
            <Col
              md={7}
              sm={6}
              className={classNames({
                'houses-route': matchesHousesRoute
              })}
              css={navCss.siteName}
            >
              <h1>
                <Link to={ROUTES.LANDING}>
                  <img
                    alt="logo"
                    src="../logo192.png"
                    css={navCss.rentalsLogo}
                  ></img>{' '}
                  <span css={navCss.rentalsLogoText}>entalsug</span>
                </Link>
              </h1>
            </Col>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="justify-content-center"
              css={navCss.navbarToggler}
            >
              <HamMenuIcon
                iconToggled={iconToggled}
                toggleHamburgerIcon={toggleHamburgerIcon}
              />
            </Navbar.Toggle>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              css={navCss.navbarCollapse1}
            >
              <Nav className="home row">
                <Nav>
                  <Nav.Link
                    eventKey="1"
                    href={ROUTES.HOUSES}
                    onClick={toggleHamburgerIcon}
                  >
                    House Listings
                  </Nav.Link>
                  <Nav.Link
                    eventKey="2"
                    as={Link}
                    to={ROUTES.ABOUT}
                    onClick={toggleHamburgerIcon}
                  >
                    About
                  </Nav.Link>
                  <Nav.Link
                    eventKey="3"
                    className="p-md-0"
                    as={Link}
                    to={ROUTES.REQUEST}
                    onClick={handleRequestClick}
                  >
                    <Button css={navCss.navRequestButton}>Request</Button>
                  </Nav.Link>
                  <React.Fragment>
                    {isLoggedIn && isAdmin ? (
                      <Nav.Link
                        eventKey="4"
                        as={Link}
                        to={ROUTES.ADD_HOUSES}
                        className="small-medium-screen"
                        onClick={handleAddHouseClick}
                      >
                        Add house
                      </Nav.Link>
                    ) : null}
                  </React.Fragment>
                  <React.Fragment>
                    {isLoggedIn && (
                      <Nav.Link
                        eventKey="7"
                        as={Link}
                        to={ROUTES.USER_HOUSES_OF_INTEREST}
                        className="small-medium-screen"
                        onClick={toggleHamburgerIcon}
                      >
                        Houses of interest
                      </Nav.Link>
                    )}
                  </React.Fragment>
                  <div css={navCss.signButton}>
                    <div className="small-medium-screen">
                      {isLoggedIn ? (
                        <span className="ml-3" onClick={toggleHamburgerIcon}>
                          <SignOutButton />
                        </span>
                      ) : (
                        <Nav.Link
                          eventKey="8"
                          as={Link}
                          to={{
                            pathname: ROUTES.SIGN_IN,
                            state: {
                              redirectLink: ROUTES.LANDING
                            }
                          }}
                          onClick={toggleHamburgerIcon}
                        >
                          <NavAuthButton label="Sign In" />
                        </Nav.Link>
                      )}
                    </div>
                    <div className="large-screen">
                      {isLoggedIn ? (
                        <div ref={ref}>
                          <div
                            className="ml-2"
                            onClick={showPopover}
                            css={navCss.largeScreen.avatar}
                          >
                            <img
                              src={profilePic || avatar}
                              className="avatar-image avatar-image--icon"
                              alt="Avatar"
                            />
                          </div>
                          <Overlay
                            show={show}
                            target={target}
                            container={ref.current}
                            rootClose={true}
                            onHide={hidePopover}
                            placement="bottom"
                          >
                            <Popover
                              id="popover-positioned-bottom"
                              style={{ width: '280px' }}
                            >
                              <Popover.Content>
                                <ul role="menu" css={navCss.largeScreen.ul}>
                                  <li role="menuitem" className="list-item">
                                    <Link
                                      className="item-link"
                                      to={ROUTES.USER_HOUSES_OF_INTEREST}
                                      onClick={hidePopover}
                                    >
                                      Houses of interest
                                    </Link>
                                  </li>
                                  {isAdmin ? (
                                    <li role="menuitem" className="list-item">
                                      <Link
                                        className="item-link"
                                        to={ROUTES.ADD_HOUSES}
                                        onClick={hidePopover}
                                      >
                                        Add house
                                      </Link>
                                    </li>
                                  ) : null}

                                  <li role="menuitem" className="list-item">
                                    <span
                                      className="item-link"
                                      onClick={hidePopover}
                                    >
                                      <SignOutButton />
                                    </span>
                                  </li>
                                </ul>
                              </Popover.Content>
                            </Popover>
                          </Overlay>
                        </div>
                      ) : (
                        <Link
                          to={{
                            pathname: ROUTES.SIGN_IN,
                            state: {
                              redirectLink: ROUTES.LANDING
                            }
                          }}
                        >
                          <NavAuthButton label="Sign In" />
                        </Link>
                      )}
                    </div>
                  </div>
                </Nav>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </div>
  );
};

const AboutNav: React.FC<NavPropsType> = ({
  isAdmin,
  isLoggedIn,
  showPopover,
  ref,
  hidePopover,
  profilePic,
  show,
  target,
  iconToggled,
  toggleHamburgerIcon,
  handleRequestClick,
  handleAddHouseClick
}) => (
  <React.Fragment>
    <div css={aboutNavCss.self}>
      <Container>
        <Navbar
          collapseOnSelect={true}
          expand="lg"
          className="align-items-center"
        >
          <Col md={6} sm={5} css={aboutNavCss.siteNameAbout}>
            <h1>
              <Link to={ROUTES.LANDING}>RentalsUG</Link>
            </h1>
          </Col>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav2"
            className="justify-content-center"
            css={navCss.navbarToggler}
          >
            <HamMenuIcon
              iconToggled={iconToggled}
              toggleHamburgerIcon={toggleHamburgerIcon}
            />
          </Navbar.Toggle>

          <Navbar.Collapse
            id="responsive-navbar-nav2"
            className="justify-content-end"
            css={navCss.navBarCollapse2}
          >
            <Nav className="about row">
              <Nav>
                <Nav.Link
                  eventKey="1"
                  as={Link}
                  to={ROUTES.HOME}
                  onClick={toggleHamburgerIcon}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  eventKey="2"
                  as={Link}
                  to={ROUTES.REQUEST}
                  onClick={handleRequestClick}
                >
                  Request
                </Nav.Link>
                <Nav.Link
                  eventKey="3"
                  as={Link}
                  to={ROUTES.HOUSES}
                  onClick={toggleHamburgerIcon}
                >
                  Houses
                </Nav.Link>
                <Nav.Link
                  eventKey="4"
                  as={Link}
                  to={ROUTES.ABOUT}
                  onClick={toggleHamburgerIcon}
                >
                  About
                </Nav.Link>
                <React.Fragment>
                  {isLoggedIn && isAdmin ? (
                    <Nav.Link
                      eventKey="6"
                      as={Link}
                      to={ROUTES.ADD_HOUSES}
                      className="small-medium-screen"
                      onClick={handleAddHouseClick}
                    >
                      Add house
                    </Nav.Link>
                  ) : null}
                </React.Fragment>
                <React.Fragment>
                  {isLoggedIn && (
                    <Nav.Link
                      eventKey="7"
                      as={Link}
                      to={ROUTES.USER_HOUSES_OF_INTEREST}
                      className="small-medium-screen"
                      onClick={toggleHamburgerIcon}
                    >
                      Houses of interest
                    </Nav.Link>
                  )}
                </React.Fragment>
                <div css={navCss.signButton}>
                  <div className="small-medium-screen">
                    {isLoggedIn ? (
                      <span className="ml-3" onClick={toggleHamburgerIcon}>
                        <SignOutButton />
                      </span>
                    ) : (
                      <Nav.Link
                        eventKey="8"
                        as={Link}
                        to={{
                          pathname: ROUTES.SIGN_IN,
                          state: {
                            redirectLink: ROUTES.LANDING
                          }
                        }}
                        onClick={toggleHamburgerIcon}
                      >
                        <NavAuthButton label="Sign In" />
                      </Nav.Link>
                    )}
                  </div>
                  <div className="large-screen">
                    {isLoggedIn ? (
                      <div ref={ref}>
                        <div
                          className="ml-2"
                          onClick={showPopover}
                          css={navCss.largeScreen.avatar}
                        >
                          <img src={profilePic || avatar} alt="Avatar" />
                        </div>
                        <Overlay
                          show={show}
                          target={target}
                          rootClose={true}
                          onHide={hidePopover}
                          placement="bottom"
                        >
                          <Popover
                            id="popover-positioned-bottom"
                            style={{ width: '280px' }}
                          >
                            <Popover.Content>
                              <ul role="menu" css={navCss.largeScreen.ul}>
                                <li role="menuitem" className="list-item">
                                  <Link
                                    className="item-link"
                                    to={ROUTES.USER_HOUSES_OF_INTEREST}
                                    onClick={hidePopover}
                                  >
                                    Houses of interest
                                  </Link>
                                </li>
                                {isAdmin ? (
                                  <li role="menuitem" className="list-item">
                                    <Link
                                      className="item-link"
                                      to={ROUTES.ADD_HOUSES}
                                      onClick={hidePopover}
                                    >
                                      Add house
                                    </Link>
                                  </li>
                                ) : null}

                                <li role="menuitem" className="list-item">
                                  <span
                                    className="item-link"
                                    onClick={hidePopover}
                                  >
                                    <SignOutButton />
                                  </span>
                                </li>
                              </ul>
                            </Popover.Content>
                          </Popover>
                        </Overlay>
                      </div>
                    ) : (
                      <Link
                        to={{
                          pathname: ROUTES.SIGN_IN,
                          state: {
                            redirectLink: ROUTES.LANDING
                          }
                        }}
                      >
                        <NavAuthButton label="Sign In" />
                      </Link>
                    )}
                  </div>
                </div>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <h1 css={aboutNavCss.heroText}>
          Find
          <br />
          your next home
          <br />
          with ease
        </h1>
      </Container>
    </div>
  </React.Fragment>
);

export default Navigation;
