import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useLocation, Route} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './firebase';
import store from './store';

const ScrollToTop = () => {
  const location = useLocation();
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return null;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <FirebaseContext.Provider value={Firebase.getInstance()}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ScrollToTop />
            <App />
          </QueryParamProvider>
        </Router>
      </FirebaseContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
