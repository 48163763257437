import React, { lazy, Suspense, useCallback } from 'react';
import './App.css';

import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navigation from '../Navigation';
import Footer from '../Footer';
import * as ROUTES from '../constants/routes';
import { useAppDispatch } from '../store';
import { selectIsLoggedIn, updatedAuthUser } from '../Auth/authSlice';
import { AddHouse } from '../Houses';
import PageLoader from '../util/PageLoader';

import ProtectedRoute from '../util/ProtectedRoute';
import * as Conditions from '../util/conditions';
import { useLazyGetCurrentUserQuery } from '../api/apiSlice';
const Houses = lazy(() => import("../Houses/Houses"));
const Admin = lazy(() => import("../Admin"));
const HouseDetails = lazy(() => import("../Houses/HouseDetails"));
const CreateRequest = lazy(() => import("../Requests"));
const LandingPage = lazy(() => import("../Landing"));
const Signin = lazy(() => import("../Auth/Signin"));
const NotFound = lazy(() => import("../404"));
const AboutPage = lazy(() => import("../About"));
const PermissionDenied = lazy(() => import("../PermissionDenied"));
const ForgotPassword = lazy(() => import("../Auth/ForgotPassword"));
const Signup = lazy(() => import("../Auth/Signup"));
const UserHousesOfInterest = lazy(() => import("../Account"));

function App() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [getAuthUser] = useLazyGetCurrentUserQuery()

  const getCurrentUser = useCallback(async () => {
    // @ts-ignore
    const user = await getAuthUser().unwrap()
    dispatch(updatedAuthUser(user));
  }, [getAuthUser, dispatch])
  React.useEffect(() => {
    if(isLoggedIn) {
      getCurrentUser()
    }
  }, [dispatch, isLoggedIn, getCurrentUser]);

  return (
    <div className="site-content">
      <header>
        <Navigation />
      </header>

      <section className="page-wrapper">
      <Suspense fallback={<PageLoader/>}>
        <Switch>
          <Route exact={true} path={ROUTES.LANDING} component={LandingPage} />
          <Route exact={true} path={ROUTES.SIGN_IN} component={Signin} />
          <Route exact={true} path={ROUTES.SIGN_UP} component={Signup} />
          <Route
            exact={true}
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route exact={true} path={ROUTES.HOME} component={LandingPage} />
          <Route exact={true} path={ROUTES.HOUSES} component={Houses} />
          <Route exact={true} path={ROUTES.ABOUT} component={AboutPage} />
          <Route
            exact={true}
            path={ROUTES.USER_HOUSES_OF_INTEREST}
            component={UserHousesOfInterest}
          />
          <ProtectedRoute
            exact={true}
            path={ROUTES.ADD_HOUSES}
            condition={Conditions.isAdmin}
            component={AddHouse}
          />
          <Route
            exact={true}
            path={`${ROUTES.REQUEST}`}
            component={CreateRequest}
          />
          <Route
            exact={true}
            path={`${ROUTES.HOUSES}/:houseId`}
            component={HouseDetails}
          />
          <ProtectedRoute
            path={ROUTES.ADMIN_PAGE}
            condition={Conditions.isAdmin}
            component={Admin}
          />
          <Route
            exact={true}
            path={ROUTES.PERMISSION_DENIED}
            component={PermissionDenied}
          />
          <Route
            exact={true}
            path={`${ROUTES.NotFound}`}
            component={NotFound}
          />
        </Switch>
        </Suspense>
      </section>

      <footer className="footer">
        <hr />
        <Footer />
      </footer>
    </div>
  );
}

export default App;
