export const handlePhoneNumberChange = (handleChange, value) => {
  handleChange({
    target: {
      name: 'phoneNumber',
      value,
      type: 'tel',
    },
    persist: () => ({}),
  });
};

export const handlePhoneNumberBlur = handleBlur => {
  handleBlur({
    target: {
      name: 'phoneNumber',
    },
  });
};
