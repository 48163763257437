/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const colors = {
  primary: '#3A8D8C'
};

const navAuthButtonCss = css({
  marginRight: '1em',
  backgroundColor: '#ffffff',
  color: colors.primary,
  fontWeight: 500,
  borderColor: colors.primary,
  '&:hover, &:active, &:visited, &:focus': {
    backgroundColor: colors.primary,
    borderColor: colors.primary
  }
});

type NavAuthButtonProps = {
  label: string;
  onClick?: any;
};

export const NavAuthButton = ({ label, onClick }: NavAuthButtonProps) => (
  <button
    className="btn btn-secondary"
    type="button"
    {...(onClick ? { onClick } : {})}
    onClick={onClick}
    css={navAuthButtonCss}
  >
    {label}
  </button>
);
