import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { logOutSuccess } from '../authSlice';
import { useAppDispatch } from '../../store';
import { useLogoutUserMutation } from '../../api/apiSlice';
import { NavAuthButton } from '../../Navigation/NavAuthButton';

const SignOutButton = () => {
  const dispatch = useAppDispatch();
  const [logoutUser] = useLogoutUserMutation();
  const history = useHistory();

  const onClickHandler = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      // @ts-ignore
      await logoutUser().unwrap();
      dispatch(logOutSuccess());
      history.push(ROUTES.LANDING);
    },
    [logoutUser, dispatch, history]
  );

  return <NavAuthButton label="Sign Out" onClick={onClickHandler} />;
};

export default SignOutButton;
