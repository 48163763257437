import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { useSelector } from 'react-redux';
import { selectAuthUser, selectIsLoggedIn } from '../Auth/authSlice';

const ProtectedRoute: React.FC<any> = ({
  component: Component,
  condition,
  ...rest
}) => {
  const authUser = useSelector(selectAuthUser);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const determineUserAccessibility = (props: any) => {
    if (condition(authUser)) {
      return <Component {...rest} {...props} />;
    } else {
      return isLoggedIn ? (
        <Redirect
          to={{
            pathname: ROUTES.PERMISSION_DENIED
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: ROUTES.SIGN_IN,
            state: {
              redirectLink: props.location.pathname
            }
          }}
        />
      );
    }
  };
  return <Route {...rest} render={determineUserAccessibility} />;
};

export default ProtectedRoute;
