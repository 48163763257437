// tslint:disable: jsx-no-lambda
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
  Alert,
  Col,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faBed,
  faHome,
  faBath,
  faCouch
} from '@fortawesome/free-solid-svg-icons';
// tslint:disable-next-line: no-submodule-imports
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { MediaTypeForArrays, HouseState } from '../../house.types';
import { useAppDispatch } from '../../../store';
import { formatedFees } from '../../../util';

import './style.scss';
import {
  selectAuthUserPhoneNumber,
  selectAuthUserId,
  selectIsLoggedIn,
  redirect,
  selectAuthUser
} from '../../../Auth/authSlice';
import { HOUSE_STATUS } from '../../house.constants';
import { useHistory, useLocation } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { addNotification } from '../../../Notifications/notificationSlice';
import HouseCard from '../../HouseCard';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';
import { useDeclineUserInterestInHouseMutation, useGetRelatedHousesQuery, useGetUserHouseOfInterestQuery, useSaveUserInterestInHouseMutation } from '../../../api/apiSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';

/******************** types ********************/
type overlayTriggerProps = {
  tipText: string;
};

type carouselProps = {
  content: MediaTypeForArrays[];
  activeIndex: number;
  isVideo: boolean;
};

type imageSectionProps = {
  images: MediaTypeForArrays[];
  videos: MediaTypeForArrays[];
  selectedItem: number;
  updateCurrentSlide: (index: number) => void;
};

type HouseDetailsProps = {
  house: HouseState;
};

type RentShareSectionProps = {
  numberOfBedRooms: number;
  selfContainedDescription: string;
  furnishedDescription: string;
  rentFee: number;
  currency: string;
};

/******************** <OverlayTriggerPopup /> ********************/
const OverlayTriggerPopup: React.FC<overlayTriggerProps> = ({
  children,
  tipText
}) => (
  <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="tooltip-bottom">{tipText}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);

const RentShareSection: React.FC<RentShareSectionProps> = ({
  numberOfBedRooms,
  selfContainedDescription,
  furnishedDescription,
  rentFee,
  currency
}) => {
  const [smShow, setSmShow] = useState(false);
  const shareUrl = window.location.href;
  const shareTitle = `${numberOfBedRooms} Bedroom ${selfContainedDescription} ${furnishedDescription} House`;
  return (
    <>
      <div className="col-7">
        <span className="rent-per-month-text">Rent per month</span>
        <span className="d-flex">
          <h3 style={{ fontSize: '36px', lineHeight: '44px' }}>
            {' '}
            {formatedFees(rentFee)}{' '}
          </h3>{' '}
          <span className="ml-2 mt-3 mt-md-4 rent-fee-ugx-text">{currency === 'usd' ? 'USD' : 'Ugx'}</span>
        </span>
      </div>
      <div className="col-5 text-right">
        <div onClick={() => setSmShow(true)} style={{ cursor: 'pointer' }}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4 0.399902C15.5513 0.399902 14.7374 0.737044 14.1373 1.33716C13.5372 1.93728 13.2 2.75121 13.2 3.5999C13.2015 3.82484 13.2266 4.04899 13.275 4.26865L6.0094 7.90146C5.70974 7.55646 5.33966 7.27965 4.92406 7.08964C4.50847 6.89963 4.05699 6.80083 3.60002 6.7999C2.75133 6.7999 1.9374 7.13704 1.33728 7.73716C0.737166 8.33728 0.400024 9.15121 0.400024 9.9999C0.400024 10.8486 0.737166 11.6625 1.33728 12.2626C1.9374 12.8628 2.75133 13.1999 3.60002 13.1999C4.05714 13.1994 4.50884 13.1009 4.92471 12.9112C5.34059 12.7215 5.71098 12.4448 6.01096 12.0999L13.2719 15.7312C13.2245 15.9509 13.2004 16.1751 13.2 16.3999C13.2 17.2486 13.5372 18.0625 14.1373 18.6626C14.7374 19.2628 15.5513 19.5999 16.4 19.5999C17.2487 19.5999 18.0626 19.2628 18.6628 18.6626C19.2629 18.0625 19.6 17.2486 19.6 16.3999C19.6 15.5512 19.2629 14.7373 18.6628 14.1372C18.0626 13.537 17.2487 13.1999 16.4 13.1999C15.9429 13.2004 15.4912 13.2989 15.0753 13.4886C14.6595 13.6783 14.2891 13.955 13.9891 14.2999L6.72815 10.6687C6.77553 10.4489 6.79962 10.2247 6.80002 9.9999C6.79849 9.77549 6.77335 9.55186 6.72502 9.33271L13.9906 5.6999C14.2905 6.04462 14.6606 6.32114 15.0762 6.51088C15.4918 6.70061 15.9432 6.79916 16.4 6.7999C17.2487 6.7999 18.0626 6.46276 18.6628 5.86264C19.2629 5.26253 19.6 4.4486 19.6 3.5999C19.6 2.75121 19.2629 1.93728 18.6628 1.33716C18.0626 0.737044 17.2487 0.399902 16.4 0.399902Z"
              fill="#5C5F62"
            />
          </svg>
        </div>
      </div>
      <Modal
        size="sm"
        show={smShow}
        onHide={setSmShow}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Share This Listing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <FacebookShareButton
              url={shareUrl}
              quote={shareTitle}
              hashtag="rentalsug"
              className=""
            >
              <FacebookIcon size={32} round />
              <span className="ml-3">FACEBOOK</span>
            </FacebookShareButton>
          </div>
          <div className="mb-3">
            <TwitterShareButton url={shareUrl} title={shareTitle} className="">
              <TwitterIcon size={32} round />
              <span className="ml-3">TWITTER</span>
            </TwitterShareButton>
          </div>
          <div className="mb-3">
            <WhatsappShareButton
              url={shareUrl}
              title={shareTitle}
              separator=":: "
              className=""
            >
              <WhatsappIcon size={32} round />
              <span className="ml-3">WHATSAPP</span>
            </WhatsappShareButton>
          </div>
          <div>
            <LinkedinShareButton
              url={shareUrl}
              summary={shareTitle}
              source="https://rentalsug.com/"
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={32} round />
              <span className="ml-3">LINKEDIN</span>
            </LinkedinShareButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

/******************** <Header /> ********************/
const Header: React.FC<HouseDetailsProps> = ({ house }) => {
  const {
    location,
    numberOfUsersInterested,
    status,
    furnishedStatus,
    selfContainedStatus,
    numberOfBedRooms,
    numberOfBathRooms,
    rentFee,
    currency
  } = house;
  const furnishedDescription = furnishedStatus ? 'Fully Furnished' : '';
  const selfContainedDescription = selfContainedStatus ? 'Self Contained' : '';
  const checkedIcon = (status: boolean) =>
    status ? faCheckCircle : faTimesCircle;
  const handlePlural = (num: number) => (num === 1 ? '' : 's');
  const localArea = location.formattedAddress?.split(',')[0];
  const studioOrHasBedrooms =
    numberOfBedRooms !== 0 ? `${numberOfBedRooms} Bedroom` : 'Studio Room';
  const hasNumberOfBedrooms =
    numberOfBedRooms !== 0
      ? `${numberOfBedRooms} Bedroom${handlePlural(numberOfBedRooms)}`
      : 'Studio';

  return (
    <div className="view-house--header">
      <div className="view-house--header--wraper">
        <div className="view-house-location--meta">
          <a href="#google-map">
            <span>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </span>
            <span>{location.formattedAddress}</span>
          </a>
        </div>
        <h3>
          {studioOrHasBedrooms} {selfContainedDescription}{' '}
          {furnishedDescription} House
        </h3>
        <div className="house-view--header--div">
          <div className="house-view-details--span">
            <span>{hasNumberOfBedrooms}</span>
            <span className="separator" />
            <span>
              {numberOfBathRooms} Bathroom{handlePlural(numberOfBathRooms)}
            </span>
            {selfContainedStatus ? (
              <>
                <span className="separator" />
                <span>
                  <span>Self Contained</span>
                </span>
              </>
            ) : null}
            {furnishedStatus ? (
              <>
                <span className="separator" />
                <span>
                  <span>Furnished</span>
                </span>
              </>
            ) : null}
          </div>

          <div className="house-view-details--icons">
            <OverlayTriggerPopup tipText={`Bedrooms: ${numberOfBedRooms}`}>
              <span>
                <FontAwesomeIcon icon={faBed} className="details-icons" />
                <span className="bedroom-count">
                  <span>{numberOfBedRooms}</span>
                </span>
              </span>
            </OverlayTriggerPopup>

            <span className="separator" />

            <OverlayTriggerPopup tipText={`Bathrooms: ${numberOfBathRooms}`}>
              <span>
                <FontAwesomeIcon icon={faBath} className="details-icons" />
                <span className="bedroom-count">
                  <span>{numberOfBathRooms}</span>
                </span>
              </span>
            </OverlayTriggerPopup>

            <span className="separator" />

            <OverlayTriggerPopup tipText="Self Contained?">
              <span>
                <FontAwesomeIcon icon={faHome} className="details-icons" />
                <span className="bedroom-count">
                  <FontAwesomeIcon
                    icon={checkedIcon(selfContainedStatus)}
                    className="utils-icons"
                  />
                </span>
              </span>
            </OverlayTriggerPopup>

            <span className="separator" />

            <OverlayTriggerPopup tipText="Furnished?">
              <span>
                <FontAwesomeIcon icon={faCouch} className="details-icons" />
                <span className="bedroom-count">
                  <FontAwesomeIcon
                    icon={checkedIcon(furnishedStatus)}
                    className="utils-icons"
                  />
                </span>
              </span>
            </OverlayTriggerPopup>
          </div>
        </div>
      </div>
      <hr />
      <div className="row ">
        <div className="col-5">
          <span>
            <svg
              fill="none"
              height="23"
              viewBox="0 0 22 23"
              width="22"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m9.99999.600098c-.2091.000013-.40989.0819-.55937.228125l-8.726564 7.137497c-.010079.00758-.019977.01539-.029687.02344l-.029688.025v.00156c-.080237.07475-.14425.1652-.188063.26573s-.06649.20899-.066624.31865c0 .21217.084285.41565.234314.56568.15003.15003.353513.23432.565682.23432h.8v8.8c0 .884.716 1.6 1.6 1.6h6.01875c-.2664-.752-.41875-1.5568-.41875-2.4 0-3.9768 3.22321-7.2 7.20001-7.2.5504 0 1.0848.0668 1.6.1844v-.9844h.8c.2122 0 .4157-.08429.5657-.23432s.2343-.35351.2343-.56568c.0001-.10993-.0225-.21869-.0663-.3195s-.108-.19151-.1884-.26644l-.0125-.00937c-.0175-.01591-.0358-.03103-.0547-.04532l-8.7359-7.14531c-.1474-.137001-.341-.213434-.54221-.214062zm6.40001 11.200002c-3.0928 0-5.6 2.5072-5.6 5.6s2.5072 5.6 5.6 5.6 5.6-2.5072 5.6-5.6-2.5072-5.6-5.6-5.6zm2.4 3.2c.2046 0 .4092.078.5656.2344.3128.3128.3128.8184 0 1.1312l-3.2 3.2c-.156.156-.3608.2344-.5656.2344s-.4096-.0784-.5656-.2344l-1.6-1.6c-.3128-.3128-.3128-.8184 0-1.1312s.8184-.3128 1.1312 0l1.0344 1.0343 2.6344-2.6343c.1564-.1564.361-.2344.5656-.2344z"
                fill={
                  status.toLocaleLowerCase() === 'taken' ? '#5C5F62' : '#009a69'
                }
              />
            </svg>
          </span>
          <span className="ml-2 text-capitalize">{status}</span>
        </div>
        <div className="col-7 mt-1 text-right people-interested-text">
          {numberOfUsersInterested} people showed interest
        </div>
      </div>
      <hr />
      <div className="row">
        <RentShareSection
          numberOfBedRooms={numberOfBedRooms}
          selfContainedDescription={selfContainedDescription}
          furnishedDescription={furnishedDescription}
          rentFee={rentFee}
          currency={currency}
        />
      </div>
      <ShowInterestSection house={house} />
      <div className="star-rating row mt-md-4">
        <span className="col-1">
          <svg
            fill="none"
            height="23"
            viewBox="0 0 22 23"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m9.99999.600098c-.2091.000013-.40989.0819-.55937.228125l-8.726564 7.137497c-.010079.00758-.019977.01539-.029687.02344l-.029688.025v.00156c-.080237.07475-.14425.1652-.188063.26573s-.06649.20899-.066624.31865c0 .21217.084285.41565.234314.56568.15003.15003.353513.23432.565682.23432h.8v8.8c0 .884.716 1.6 1.6 1.6h6.01875c-.2664-.752-.41875-1.5568-.41875-2.4 0-3.9768 3.22321-7.2 7.20001-7.2.5504 0 1.0848.0668 1.6.1844v-.9844h.8c.2122 0 .4157-.08429.5657-.23432s.2343-.35351.2343-.56568c.0001-.10993-.0225-.21869-.0663-.3195s-.108-.19151-.1884-.26644l-.0125-.00937c-.0175-.01591-.0358-.03103-.0547-.04532l-8.7359-7.14531c-.1474-.137001-.341-.213434-.54221-.214062zm6.40001 11.200002c-3.0928 0-5.6 2.5072-5.6 5.6s2.5072 5.6 5.6 5.6 5.6-2.5072 5.6-5.6-2.5072-5.6-5.6-5.6zm2.4 3.2c.2046 0 .4092.078.5656.2344.3128.3128.3128.8184 0 1.1312l-3.2 3.2c-.156.156-.3608.2344-.5656.2344s-.4096-.0784-.5656-.2344l-1.6-1.6c-.3128-.3128-.3128-.8184 0-1.1312s.8184-.3128 1.1312 0l1.0344 1.0343 2.6344-2.6343c.1564-.1564.361-.2344.5656-.2344z"
              fill="#5C5F62"
            />
          </svg>
        </span>
        <span className="col">
          <h6>House Tour Available</h6>
          Classy {studioOrHasBedrooms} house in {localArea}. Show interest to stay with peace of
          mind.
        </span>
        <span style={{ color: '#8C9196' }} className="col-1">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </div>
    </div>
  );
};

/******************** <ImageSection /> ********************/
const ImageSection: React.FC<imageSectionProps> = ({
  images: initialImages,
  videos,
  selectedItem,
  updateCurrentSlide
}) => {
  const images = [...initialImages];

  if (videos.length > 0) {
    images.unshift(videos[0]);
  }

  return (
    <>
      {
        <Carousel
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          dynamicHeight={true}
          selectedItem={selectedItem}
          onChange={updateCurrentSlide}
        >
          {images.map((image, key) => {
            if (key === 0) {
              return videos.length > 0 ? (
                <div key={key}>
                  <video
                    className="d-block w-100 view-details-image"
                    src={image.url}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    controls={true}
                    loop={true}
                  />{' '}
                </div>
              ) : (
                <div key={key}>
                  <img
                    className="d-block w-100 view-details-image"
                    src={image.url}
                    alt={image.name}
                  />
                </div>
              );
            }
            return (
              <div key={key}>
                <img
                  className="d-block w-100 view-details-image"
                  src={image.url}
                  alt={image.name}
                />
              </div>
            );
          })}
        </Carousel>
      }
    </>
  );
};

const ShowInterestSection: React.FC<HouseDetailsProps> = ({ house }) => {
  const {
    description,
    rentFee,
    uid,
    images,
    location,
    status,
    owner,
    numberOfBedRooms,
    selfContainedStatus
  } = house;

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userId = useSelector(selectAuthUserId);
  const authUser = useSelector(selectAuthUser);
  const [saveUserInterestInHouse, { isLoading: isSavingUserHouseInterest }] = useSaveUserInterestInHouseMutation()
  const [declineUserInterestInHouse, { isLoading: isDecliningUserHouseInterest }] = useDeclineUserInterestInHouseMutation()
  const userInterestQueryParams = userId ? { userId, houseId: uid } : skipToken
  const { data: userInterest, isFetching } = useGetUserHouseOfInterestQuery(userInterestQueryParams)
  const userPhoneNumber = useSelector(selectAuthUserPhoneNumber);
  const [edit, setEdit] = useState(false);
  const [showInterested, setShowInterested] = useState(false);
  const [phoneInput, setPhoneInput] = useState('');
  const [inputError, setInputError] = useState(true);
  const [startEdit, setStartEdit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();
  const currentLocation = useLocation();

  const isLoading = useMemo(() => isSavingUserHouseInterest || isDecliningUserHouseInterest,[isSavingUserHouseInterest, isDecliningUserHouseInterest])

  const houseOfInterest = useMemo(() => userInterest?.length && userInterest[0], [userInterest])

  const dispatch = useAppDispatch();

  const buttonClass = houseOfInterest ? 'reserve--btn darken' : 'reserve--btn';

  const houseURL = currentLocation.pathname;

  const isValidatePhoneNo = (phoneNo: string) => {
    let errorMsg = '';
    if (phoneNo.length) {
      if (phoneNo.length < 13) {
        errorMsg = 'The number should be at least 9 characters long'; // considering 9 because +256 is automatically added
      }
      if (phoneNo.length > 13) {
        errorMsg = 'The number should be at most 10 characters long'; // considering 10 because +256 is automatically added and the first "0" is ignored
      }
      if (errorMsg) {
        setInputError(true);
        setErrorMsg(errorMsg);
        return false;
      }
      setInputError(false);
      return true;
    }
    setInputError(true);
    setErrorMsg('Phone number is a required field');
    return false;
  };

  const handleUserChoice = async () => {
    if (!isLoggedIn) {
      history.push(ROUTES.SIGN_IN, {
        redirectLink: currentLocation.pathname,
        isRedirected: true
      });
      dispatch(redirect(currentLocation.pathname));
    }
    if (userPhoneNumber && !houseOfInterest) {
      saveUserInterest();
      setShowInterested(true);
    } else if (houseOfInterest) {
      await declineUserInterestInHouse({ houseId: uid, houseInterestId: houseOfInterest.uid }).unwrap();
      setShowInterested(false);
    } else {
      setEdit(true);
      setShowInterested(false);
    }
  };

  const ShowSuccessAlert = () => {
    const [showA, setShowA] = React.useState(true);
    const toggleShowA = () => setShowA(!showA);
    return (
      <Alert
        variant="success"
        show={showA}
        onClose={toggleShowA}
        dismissible={true}
      >
        <p>
          We've received your request and are processing it. You will be contacted shortly.
          Thanks for using the service.
        </p>
      </Alert>
    );
  };

  const saveUserInterest = async () => {
    await saveUserInterestInHouse({
      houseId: uid,
      houseImage: images[0].url,
      rentFee,
      location: location.formattedAddress || '',
      phoneNumber: userPhoneNumber || phoneInput,
      houseURL,
      user: authUser
    }).unwrap()
    const loggedUser = authUser;
    const currentUrl = window.location.href;
    const newNotification = {
      title: `New Interest from ${loggedUser.username}`,
      description,
      type: 'Interest',
      reciever: {
        userName: 'RentalsUG',
        email: 'info@rentalsug.com',
        role: 'agent'
      },
      locations: [location.formattedAddress],
      numberOfRooms: numberOfBedRooms,
      selfContainedStatus: selfContainedStatus,
      moveInDates: 'Not required',
      priceRange: rentFee,
      phoneNumber: userPhoneNumber || phoneInput,
      redirectUrl: currentUrl
    };
    dispatch(addNotification(newNotification));
  };

  const handlePhoneNumberChange = (value: string) => {
    const trimmedValue = value ? value.trim() : '';
    setPhoneInput(trimmedValue);
    setStartEdit(true);
  };

  const handleEdit = () => {
    if (isValidatePhoneNo(phoneInput)) {
      handleCancel();
      saveUserInterest();
    }
  };

  const handleCancel = () => {
    setPhoneInput('');
    setInputError(true);
    setStartEdit(false);
    setEdit(false);
  };

  return (
    <div className="house-view-details--wrapper">
      <div className="reserve-information--wrapper">
        <div>
          <div className="reserve-information">
            {!edit && (
              <>
                {status === HOUSE_STATUS.AVAILABLE &&
                owner.uid !== userId ? (
                  <Button
                    className={buttonClass}
                    onClick={handleUserChoice}
                    disabled={isLoading}
                  >
                    {(isLoading || isFetching) && (
                      <Spinner animation="border" variant="light" />
                    )}
                    {!isLoading && !isFetching && (
                      <>
                        {houseOfInterest ? (
                          <OverlayTriggerPopup tipText="Click to cancel interest in the house">
                            <span>
                              Showed interest
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="mx-2"
                              />
                            </span>
                          </OverlayTriggerPopup>
                        ) : (
                          'Show interest'
                        )}
                      </>
                    )}
                  </Button>
                ) : null}
                {!isLoading && showInterested && <ShowSuccessAlert />}
              </>
            )}

            {!userPhoneNumber && edit && (
              <form>
                <OverlayTriggerPopup tipText="Cancel Edit?">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="close-edit"
                    onClick={handleCancel}
                  />
                </OverlayTriggerPopup>
                <p className="reserve-contact">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span>
                    Please enter your phone number for follow up about this
                    house{' '}
                  </span>
                </p>
                <PhoneInput
                  type="tel"
                  onChange={handlePhoneNumberChange}
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Phone Number"
                  required={true}
                  value={phoneInput}
                />
                {inputError && startEdit && <sub>{errorMsg}</sub>}
                <Button onClick={handleEdit} className="submit--btn">
                  Submit
                </Button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/******************** <DetailsSection /> ********************/
const DetailsSection: React.FC<HouseDetailsProps> = ({ house }) => {
  const {
    description,
    furnishedStatus,
    numberOfBedRooms,
    numberOfBathRooms,
    selfContainedStatus,
    parkingSpace
  } = house;
  return (
    <div className="house-view-details--wrapper">
      <div className="house-view-details--div mt-md-5">
        <div className="house-view-description">
          <h2>About This House</h2>
          <p>{description}</p>
        </div>
        <div className="amenities">
          <h4>Other Facilities</h4>
          <ul>
            <li>
              <span className="bold">Parking Spaces </span>: {parkingSpace}
            </li>
            <li>
              <span className="bold">Bedrooms </span>: {numberOfBedRooms}
            </li>
            <li>
              <span className="bold"> Bathrooms</span>: {numberOfBathRooms}
            </li>
            <li>
              <span className="bold">Self-Contained </span> :{' '}
              {selfContainedStatus ? 'Yes' : 'No'}
            </li>
            <li>
              <span className="bold">Fully Furnished </span>:{' '}
              {furnishedStatus ? 'Yes' : 'No'}
            </li>
          </ul>
        </div>
        <hr />
      </div>
    </div>
  );
};

/******************** main component ********************/

const MapMarker: React.FC<{ text: String; lat: Number; lng: Number }> = ({
  text
}) => (
  <div>
    <svg
      style={{ marginTop: '-5rem', marginLeft: '-2rem' }}
      fill="none"
      height="73"
      viewBox="0 0 60 73"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m30 .333496c-16.2 0-29.33334 13.133304-29.33334 29.333304 0 19.2907 19.07424 28.5278 21.46354 30.6771 2.44 2.1947 4.4523 7.1117 5.2656 9.901.3947 1.3547 1.5055 2.0384 2.6042 2.0678 1.1013-.032 2.2095-.7158 2.6042-2.0678.8133-2.7893 2.8256-7.7037 5.2656-9.901 2.3893-2.1493 21.4635-11.3864 21.4635-30.6771 0-16.2-13.1333-29.333304-29.3333-29.333304zm.0052 16.000004c.2813 0 .5595.0883.7969.2656l10.6667 8c.5866.4427.7056 1.2805.2656 1.8698-.2614.3493-.6651.5313-1.0677.5313v13.3333h-8v-8h-5.3334v8h-8v-13.3594c-.4053 0-.8063-.1559-1.0677-.5052-.44-.5893-.3185-1.4271.2709-1.8698l10.6666-8c.2374-.1773.5208-.2656.8021-.2656z"
        fill="#ef5251"
      />
    </svg>
  </div>
);

const ViewHouse: React.FC<HouseDetailsProps> = ({ house }) => {
  const { location, images, videos } = house;
const history = useHistory();
  const options = {
    rentFee: {
      greater: house?.rentFee! + 100000,
      lesser: house?.rentFee! - 100000
    },
    rooms: house?.numberOfBedRooms
  };
  const {
    data: relatedHouses
  } = useGetRelatedHousesQuery(options)
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fillPrevColor, setFillPrevColor] = useState('#BABEC3');
  const [fillNextColor, setFillNextColor] = useState('#5C5F62');
  const googleMapsAPIKey: string = process.env.REACT_APP_API_KEY || '';
  const localArea = location.formattedAddress?.split(',')[0];
  const thumbCount = document.querySelectorAll('.thumbs-wrapper > ul > li')
    .length;

  const relatedHousesWithoutDisplayedHouse = useMemo(() => relatedHouses?.filter((house_: any) => house_.uid !== house.uid),[relatedHouses, house.uid])

  const updateCurrentSlide = (index: number) => {
    thumbCount - 1 > currentSlide
      ? setFillNextColor('#5C5F62')
      : setFillNextColor('#BABEC3');
    currentSlide > 0
      ? setFillPrevColor('#5C5F62')
      : setFillPrevColor('#BABEC3');
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  return (
    <div className="view-house--wrapper container">
      <div className="view-house--div container">
        <div className="row image-and-details-wrapper">
          <div className="col-md-7">
            <nav aria-label="breadcrumb">
              <ol
                className="d-flex breadcrumb p-0"
                style={{
                  color: '#6D7175',
                  lineHeight: '16px',
                  marginBottom: '1.5rem',
                  fontSize: '12px'
                }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="/houses"
                    className="mr-2 pe-auto breadcrumb-item fs-1"
                  >
                    House Listings{' '}
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a
                    href={`/houses?location=${location.formattedAddress}`}
                    className="ml-2 pe-auto"
                  >
                    {' '}
                    {localArea}{' '}
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  House
                </li>
              </ol>
            </nav>
            <ImageSection
              images={images}
              videos={videos}
              selectedItem={currentSlide}
              updateCurrentSlide={updateCurrentSlide}
            />
            <div className="prev-next-icons">
              <div className="" style={{ cursor: 'pointer', position: 'absolute' }}>
                <span onClick={() => setCurrentSlide(currentSlide - 1)}>
                  <svg
                    className={`carousel-prev-icon ${
                      currentSlide > 0 ? '' : 'inactive'
                    }`}
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.4596 0.540478C6.71344 0.794319 6.71344 1.20588 6.4596 1.45972L1.91921 6.0001L6.4596 10.5405C6.71344 10.7943 6.71344 11.2059 6.4596 11.4597C6.20575 11.7136 5.7942 11.7136 5.54036 11.4597L0.540356 6.45972C0.286515 6.20588 0.286515 5.79432 0.540356 5.54048L5.54036 0.540478C5.7942 0.286637 6.20575 0.286637 6.4596 0.540478Z"
                      fill={fillPrevColor}
                    />
                  </svg>
                </span>
              </div>
              <div
                className=""
                style={{ textAlign: 'end', cursor: 'pointer', float: 'right' }}
              >
                <span onClick={() => setCurrentSlide(currentSlide + 1)}>
                  <svg
                    className={`carousel-prev-icon ${
                      thumbCount - 1 > currentSlide ? '' : 'inactive'
                    }`}
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.540356 0.540478C0.794197 0.286637 1.20575 0.286637 1.4596 0.540478L6.4596 5.54048C6.71344 5.79432 6.71344 6.20588 6.4596 6.45972L1.4596 11.4597C1.20575 11.7136 0.794197 11.7136 0.540356 11.4597C0.286515 11.2059 0.286515 10.7943 0.540356 10.5405L5.08074 6.0001L0.540356 1.45972C0.286515 1.20588 0.286515 0.794319 0.540356 0.540478Z"
                      fill={fillNextColor}
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-5 pl-4 mt-5">
            <Header house={house} />
          </div>
        </div>

        <DetailsSection house={house} />

        <div className="google-map" id="google-map">
          {location.latitude && location.longitude && (
            <GoogleMapReact
              options={{ mapTypeId: 'roadmap' }}
              bootstrapURLKeys={{ key: googleMapsAPIKey }}
              defaultCenter={{
                lat: location.latitude,
                lng: location.longitude
              }}
              defaultZoom={15}
            >
              <MapMarker
                text="here"
                lat={location.latitude}
                lng={location.longitude}
              />
            </GoogleMapReact>
          )}
        </div>
        <div>
          {relatedHousesWithoutDisplayedHouse?.length ? (
            <p className="related-listing-text">Related Listings</p>
          ) : null}
          <Row className="houses-list mt-4 mb-5">
            {relatedHousesWithoutDisplayedHouse?.length
            // Should we display only three related houses or all related houses? Which criteria should we use to consider houses to be related?
              ? relatedHousesWithoutDisplayedHouse.slice(0, 3).map((house: any) => (
                  <Col key={house.uid} xs={12} sm={6} lg={4} className="mb-5">
                    <HouseCard house={house} detailsRoute={ROUTES.HOUSES} />
                  </Col>
                ))
              : null}
          </Row>
        </div>
        <div className="browse-more">
          <Button className="" onClick={(e: any) => {
            e.preventDefault()
            // @ts-ignore
            history.goBack()
          }} >
            Go back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewHouse;
