import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../store';
import { Container, Row, Col } from 'react-bootstrap';
import * as ROUTES from '../constants/routes';
import { HousesLoader, Loading } from './HouseLoader';
import HouseCard from './HouseCard';
import './styles.css';
import { HOUSES_BATCH_LIMIT } from './house.constants';
import {  useLocation } from 'react-router-dom';
import { apiSlice, housesAdaptor, housesSelectors, useLazyGetHousesQuery } from '../api/apiSlice';

export const House: React.FC<any> = ({ house }) => {
  return (
    <>{house && <HouseCard house={house} detailsRoute={ROUTES.HOUSES} />}</>
  );
};

const Houses = () => {
  const currentPage = useRef(1);
  const loaderRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const currentLocation = useLocation();
  const locationSearchParams: any = currentLocation.search.split("=")[1]
  // const [searchParams, setSearchParam] = useState(locationSearchParams);

  const [getHouses, { isFetching }] = useLazyGetHousesQuery();

    // Listen For house updates on page 1
    const {houses, hasMore } =
    apiSlice.endpoints.getHouses.useQueryState({ page: 1 }, {
      selectFromResult: (result) => {
        return {
          hasMore: result.data?.hasMore,
          houses: housesSelectors.selectAll(
              result.data ?? housesAdaptor.getInitialState()
            ),
        };
      },
    });

  const fetchFirstPage = async () => {
    await getHouses({ page: currentPage.current });
  };

  const fetchMoreHouses = useCallback(async () => {
    if(!hasMore || isFetching) return;
    currentPage.current += 1;
    await getHouses({ page: currentPage.current });
  }, [getHouses, hasMore, isFetching]);

  // let searchParamsX = ""
  // if (searchParams) {
  //   searchParamsX = searchParams.replaceAll(/%\d+/g, " ")
  // }
  // const [isSearchParams, setIsSearchParam] = useState(searchParamsX !== "");
  const load = React.useCallback(
    entries => {
      const target = entries[0];

      if (
        !isFetching &&
        target.isIntersecting &&
        hasMore
      ) {
        fetchMoreHouses();
      }
    },
    [fetchMoreHouses, isFetching, hasMore]
  );

  useEffect(() => {
    // Fetch First Page On Init
    if(!houses.length) {
      fetchFirstPage()
    }
    // Required to execute only on the first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const loaderRefCopy = loaderRef;
    const options = {
      root: null, // by default it is the window
      rootMargin: '0px',
      threshold: 0.23
    };

    const observer = new IntersectionObserver(load, options);

    if (loaderRef && loaderRef.current) {
      // @ts-ignore we can safely ignore the typescript errors here because loaderRef.current won't be null
      observer.observe(loaderRef.current);
    }

    if (loaderRef && loaderRef.current) {
      return () => {
        // @ts-ignore
        observer.unobserve(loaderRefCopy.current);
      };
    }
  }, [load, loaderRef, dispatch]);

  return (
    <>
      <Container className="items-container">
          <nav aria-label="breadcrumb" className="ml-3 ml-md-0">
            <ol className="d-flex breadcrumb p-0" style={{color: "#6D7175", lineHeight: "16px", fontSize: '12px', marginBottom: "1.5rem"}}>
            <li className="breadcrumb-item"> {currentLocation.search !== "" ? <a href="/houses" className="mr-2 pe-auto breadcrumb-item fs-1">House Listings </a> : "House Listings"}</li>
              {
                currentLocation.search !== "" ? <li className="breadcrumb-item active" aria-current="page">{locationSearchParams.replaceAll(/%\d+/g, " ")} </li> : ""
              }
            </ol>
          </nav>
        <Row className="houses-list">
          {(!houses.length || houses.length === 1) && isFetching ? (
            <HousesLoader />
          ) : (
            <>
              {houses.length
                ? houses.map(house => (
                    <Col xs={12} sm={6} lg={4} className="mb-5" key={house.uid}>
                      <House house={house} />
                    </Col>
                  ))
                : null}
            </>
          )}
        </Row>
      </Container>
      <div ref={loaderRef}>
        {!isFetching && !houses.length && <p className="text-center pb-5" >No houses yet</p>}
        {houses.length >= HOUSES_BATCH_LIMIT && isFetching && (
          <div className="text-center pb-5" >
            <Loading />
          </div>
        )}
      </div>
    </>
  );
};

export default Houses;
