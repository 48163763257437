import { HouseType } from './house.types';

export const DESCRIBE_MAX_CHAR_COUNT = 1500;
export const INTERNET_CONNECTION_ERROR = 'Something went wrong, try checking your internet connection';
export const HOUSE_STATUS = {
  PENDING: 'pending',
  DELETED: 'deleted',
  TAKEN: 'taken',
  AVAILABLE: 'available'
};
export const HOUSE_INTEREST_STATUS = {
  PENDING: 'pending',
  CANCELLED: 'cancelled'
};
export const HOUSES_BATCH_LIMIT = 6;

export const INITIAL_STATE: HouseType = {
  uid: '',
  numberOfBedRooms: '',
  numberOfBathRooms: '',
  selfContainedStatus: false,
  furnishedStatus: false,
  parkingSpace: '',
  location: {},
  rentFee: '',
  description: '',
  phoneNumber: '',
  currency: '',
  image: false,
  images: {},
  videos: {},
  errors: {},
  imagesUploading: false,
  videosUploading: false
};
