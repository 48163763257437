import { AppDispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationType } from './notification.types';

import axios from 'axios';
import getErrorMessage from '../util/getErrorMessage';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

type NotificationIntialStateType = {
  details: NotificationType[];
  message: string | null;
  success: boolean;
  error: boolean;
  isLoading: boolean;
  unsubscribedFromRequest: string | null;
};

const notificationIntialState: NotificationIntialStateType = {
  details: [],
  message: null,
  error: false,
  success: false,
  isLoading: false,
  unsubscribedFromRequest: null
};

export const notificationReducer = createSlice({
  name: 'notification',
  initialState: notificationIntialState,
  reducers: {
    isLoading: state => {
      state.isLoading = true;
    },
    addedSuccessfully: (state, action) => {
      state.error = false;
      state.success = true;
      state.message = action.payload;
      state.isLoading = false;
    },
    failureUponAddition: (state, action) => {
      state.error = true;
      state.success = false;
      state.message = action.payload;
      state.isLoading = false;
    }
  }
});

export const addNotification = (newNotification: NotificationType) => async (
  dispatch: AppDispatch
) => {
  dispatch(isLoading());
  try {
    const response = await axios({
      method: 'post',
      url: 'notification',
      data: newNotification
    });
    dispatch(addedSuccessfully(response.data.message));
  } catch (error) {
    dispatch(failureUponAddition(getErrorMessage(error)));
  }
};

export const {
  addedSuccessfully,
  failureUponAddition,
  isLoading
} = notificationReducer.actions;


export default notificationReducer.reducer;
