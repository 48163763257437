import getErrorMessage from '../util/getErrorMessage';
function tryCatchWrapper(f: Function){
  return async function(this: any, ...args: unknown[]) {
    return f.apply(this, args)
      .then((data: unknown) => ({ data }))
      .catch((error: unknown) => {
      const message = getErrorMessage(error)
      return { error: message }
    })
  }
}

/**
 * This function wraps the provided function with try catch blocks.
 * It prevents us from duplicating try catch blocks on functions - especially when making firebase calls
 * @param f Function that we want to execute
 * @param params Optional
 * @returns Promise<any>
 */
export default async function firebaseTryCatchWrapper(f: Function, params?: unknown){
  if(params) {
    return tryCatchWrapper(f)(params)
  }
  return tryCatchWrapper(f)()
}
