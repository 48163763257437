export const SOCIAL_AUTH_SIGN_IN_SUCCESS = 'Social auth sign in success';
export const EMAIL_PASSWORD_SIGN_IN_SUCCESS = 'Email password sign in success';
export const SIGN_UP_SUCCESS = 'Signed up successfully';
export const SIGN_IN_SUCCESS = 'Signed in successfully';
export const FIREBASE_NETWORK_ERROR =
  'A network error (such as timeout, interrupted connection or unreachable host) has occurred.';
export const RENTALSUG_NETWORK_ERROR =
  'Network error. Please check your internet connection or try again later.';
export const POPUP_CLOSED_ERROR =
  'The popup has been closed by the user before finalizing the operation.';
export const EMAIL_SENT_SUCCESS = 'Email sent successfully';
