import * as yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const locationError = 'A valid location on google maps is required';
const step1Object = {
  numberOfBedRooms: yup
    .string()
    .required('Number of bed rooms is a required field')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(1),
  numberOfBathRooms: yup
    .string()
    .required('Number of bath rooms is a required field')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(1),
  parkingSpace: yup
    .string()
    .required('Parking space is a required field')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(0),
  rentFee: yup.string().required('Rent fee is a required field').matches(/^[0-9]+$/, 'Must be only digits'),
  phoneNumber: yup
    .string()
    .test('phone-number-test', 'Invalid phone number',
      function(value) {
          return isPossiblePhoneNumber(value) === true
      })
    .required('Phone number is a required field')
};
const step2Object = {
  description: yup.string().required('Description is a required field'),
  image: yup.bool().oneOf([ true ], 'Image(s) are required'),
  location: yup.object().shape({
    formattedAddress: yup.string().required(locationError),
    url: yup.string().required(locationError),
    icon: yup.string().required(locationError),
    placeId: yup.string().required(locationError)
  })
};

export const addHouseFormSchema = yup.object().shape({
  ...step1Object,
  ...step2Object
});
export const step1Schema = yup.object().shape(step1Object);
export const step2Schema = yup.object().shape(step2Object);
