import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Row, Badge, Card } from 'react-bootstrap';
import { MdLocationOn } from 'react-icons/md';
import { formatedFees } from '../util';
import { FaBed, FaBath, FaParking } from 'react-icons/fa';
import { HouseState } from './house.types';
import { HOUSE_STATUS } from './house.constants';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles.css';

type HouseCardProps = {
  house: HouseState;
  detailsRoute: string;
};

const HouseCard: React.FC<HouseCardProps> = ({ house, detailsRoute }) => {
  const badgeClass =
    house.status === HOUSE_STATUS.AVAILABLE
      ? 'status-button'
      : 'status-button-darker';
  return (
    <Card className="rounded">
      <Carousel infiniteLoop showThumbs={false}>
        {house?.images.slice(0, 3).map((image, key) => (
          <div key={key}>
          <Link to={`${detailsRoute}/${house.uid}`}>
              <img
                className="d-block w-100 house-container-image"
                // src={`${image.thumbnail ? image.url : image.url}`} Thumbnail isn't working anymore. We will investigate this later.
                src={image.url}
                alt={image.name}
              />
            </Link>
          </div>
        ))}
      </Carousel>
      <Card.Body>
        <Link to={`${detailsRoute}/${house.uid}`}>
          <Row>
            <Col xs={9} md={6} className="text-capitalize house-details-type">
              {house?.selfContainedStatus ? 'Self contained' : null}
            </Col>
            <Col xs={3} md={6}>
              <h5 style={{ float: 'right' }}>
                <Badge className={badgeClass} variant="success">
                  {house.status.toUpperCase()}
                </Badge>
              </h5>
            </Col>
          </Row>
          <h4 className="font-weight-bold">
            {house.currency ? house.currency.toUpperCase() : 'UGX'} {formatedFees(house?.rentFee)}
          </h4>
          <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            <MdLocationOn /> {house?.location.formattedAddress}
          </div>
          <hr />
          <Row>
            <Col>
              <span className="house-details-specific-text"> Bedroom</span>
              <span className="ml-1">
                <FaBed /> {house?.numberOfBedRooms}{' '}
              </span>
            </Col>
            <Col>
              <span className="house-details-specific-text"> Bathroom</span>
              <span className="ml-1">
                <FaBath /> {house?.numberOfBathRooms}{' '}
              </span>
            </Col>
            <Col>
              <span className="house-details-specific-text"> Parking</span>
              <span className="ml-1">
                <FaParking /> {house?.parkingSpace}{' '}
              </span>
            </Col>
          </Row>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default HouseCard;
