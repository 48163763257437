import { createSlice } from '@reduxjs/toolkit';
import { AuthUserType } from '../firebase';
import { RootState } from '../store';
import * as cookie from '../util';
import * as ROLES from '../constants/roles';

export const UNSUBSCRIBED_FROM_AUTH_USER =
  'Successfully unsubscribed from auth user';
export const LOGOUT_SUCCESS = 'logged out successfully';

type AuthState = {
  authUser: AuthUserType;
  isLoggedIn: boolean;
  message: string | null;
  redirectTo: string;
  error: boolean;
  isLoading: boolean;
  socialAuthError: boolean;
  socialAuthIsLoading: boolean;
};

const cookieAuthUser = cookie.getCookie('authUser');
const emptyAuthUser: AuthUserType = {
  email: '',
  uid: '',
  username: '',
  photoUrl: '',
  roles: {}
};
const authUserInitialState: AuthUserType = cookieAuthUser
  ? JSON.parse(cookieAuthUser)
  : emptyAuthUser;

export const initialState: AuthState = {
  authUser: authUserInitialState,
  isLoggedIn: !!authUserInitialState.email,
  message: null,
  redirectTo: '',
  error: false,
  isLoading: false,
  socialAuthError: false,
  socialAuthIsLoading: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updatedAuthUser: (state, action) => {
      state.authUser = action.payload ?? emptyAuthUser;
    },
    logOutSuccess: state => {
      state.isLoggedIn = false;
      state.message = LOGOUT_SUCCESS;
      state.error = false;
      state.authUser = emptyAuthUser;
    },
    redirect: (state, action) => {
      state.redirectTo = action.payload;
    },
    signInSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.error = false;
      state.message = action.payload;
      state.socialAuthError = false;
    },
    socialAuthSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.socialAuthError = false;
    },
    signUpSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.message = action.payload;
    },
    signInUserSuccess: (state, action) => {
      state.isLoggedIn = true;
    }
  }
});

export const {
  updatedAuthUser,
  logOutSuccess,
  redirect,
  signInSuccess,
  signUpSuccess,
  isLoading,
  signInUserSuccess,
  socialAuthSuccess,
} = authSlice.actions;

export const selectAuthUser = (state: RootState) => state.auth.authUser;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthUserPhoneNumber = (state: RootState) =>
  state.auth.authUser.phoneNumber;
export const selectAuthUserId = (state: RootState) => state.auth.authUser.uid;
export const selectProfilePic = (state: RootState) =>
  state.auth.authUser.photoUrl;
export const selectIsAdmin = (state: RootState) =>
  state.auth.authUser.roles && !!state.auth.authUser.roles[ROLES.ADMIN];

export default authSlice.reducer;
