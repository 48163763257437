import React from 'react';
import { Schema, ValidationError } from 'yup';

export const useForm = (initialValues: any, schema: Schema<any>) => {
  const [inputs, setInputs] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  const handleChange = (event: React.FormEvent) => {

    event.persist();
    const target = event.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;    
    setInputs((inputs: any) => ({ ...inputs, [target.name]: value }));
  };

  const initialState = JSON.stringify(initialValues);

  const validateField = (field: string) => {
    schema
    .validateAt(field, inputs)
    .then(() => {      
      setErrors({
        ...errors,
        [field]: null
      });
    })
    .catch((err: { message: string}) => {
      setErrors({
        ...errors,
        [field]: err.message
      });
    });
  };

  const handleBlur = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    setTouched({
      ...touched,
      [name]: true
    });
    validateField(name);
  };

  React.useEffect(() => {
    setInputs(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      schema
        .validate(inputs, { abortEarly: false })
        .then(() => resolve({}))
        .catch(e => {
          const returnedErrors: { [key: string]: string} = {};
          e.inner.forEach((error: ValidationError) => {
            returnedErrors[error.path] = error.message;
          });
          setErrors({ ...errors, ...returnedErrors });
          reject();
        });
    });
  };

  return {
    inputs,
    setInputs,
    handleChange,
    handleBlur,
    errors,
    touched,
    setErrors,
    validateForm
  };
};
