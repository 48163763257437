import React from 'react';
import DotLoader from 'react-spinners/DotLoader';
import { css } from '@emotion/react';

const PageLoader = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div className="mt-5">
      {' '}
      <DotLoader
        color={'#00a69c'}
        loading={true}
        css={override}
        size={150}
      />{' '}
    </div>
  );
};
export default PageLoader;
