export const LANDING = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const HOME = '/home';
export const HOUSES = '/houses';
export const ADD_HOUSES = '/houses/add';
export const NotFound = '*';
export const ABOUT = '/about';
export const USER_HOUSES_OF_INTEREST = '/me/houses-of-interest';
export const REQUEST = '/request';
export const FORGOT_PASSWORD = '/forgot-password';
export const PERMISSION_DENIED = '/permission-denied';
export const SEND_FEEDBACK = '/send-feedback';
export const ADMIN_REQUESTS = '/archon/requests';
export const ADMIN_HOUSES = '/archon/houses';
export const ADMIN_PAGE = '/archon';
