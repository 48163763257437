import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter } from '@reduxjs/toolkit';

import Firebase from '../firebase';
import firebaseTryCatchWrapper from '../util/firebaseTryCatchWrapper';

import {
  HouseState,
} from '../Houses/house.types';
import { HOUSES_BATCH_LIMIT } from '../Houses/house.constants';
import * as cookie from '../util'; // TODO Add cookie util to a different util. The currently exported module contains other utils
import { EMAIL_PASSWORD_SIGN_IN_SUCCESS, EMAIL_SENT_SUCCESS } from '../Auth/auth.constant';
import { LOGOUT_SUCCESS } from '../Auth/authSlice';


const housesAdaptor = createEntityAdapter<HouseState>({
  selectId: (house) => house.uid,
})

const getDataFromHousesAdaptor = (data: any) => housesAdaptor.addMany(
  housesAdaptor.getInitialState({
    hasMore: !Firebase.getInstance().endOfPagination,
  }),
  data
);

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  baseQuery: fakeBaseQuery(),
  keepUnusedDataFor: 3600, // unused data(when no component is subscribing to it) will be removed from the cache after 1hr. The default is 60 seconds.
  tagTypes: ['Request', 'House', 'RelatedHouse', 'UserHouseOfInterest', 'RecentHouse', 'HousesSearchResults'],
  endpoints: builder => ({
    getRequests: builder.query({
      async queryFn() {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().onRequestsListener);
        return response;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'Request', id: 'LIST' },
        ...result.map(({ uid }: any) => ({ type: 'Request', id: uid }))
      ]
    }),
    getRequest: builder.query({
      async queryFn(requestId: string) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().onRequestListener, requestId);
        return response;
      },
      providesTags: (result, error, arg) => [{ type: 'Request', id: arg }]
    }),
    addRequest: builder.mutation({
      async queryFn(newRequest: any) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().doAddRequest, newRequest);
        if(response.error) {
          return response
        }
        return { data: response.data.id }
      },
      async onCacheEntryAdded(
        arg,
        {
          dispatch,
          cacheDataLoaded
        }) {
        try {
          const cachedData = await cacheDataLoaded
          dispatch(
          apiSlice.util.updateQueryData('getRequests', undefined, draft => {
            draft.unshift({ uid: cachedData.data, ...arg, location: arg.locations })
          })
        )
        } catch {}
      }
    }),
    updateRequest: builder.mutation({
      async queryFn(requestUpdates: any) {
        const { messages, ...rest } = requestUpdates
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().onUpdateRequest, rest);
        if(response.error) {
          return response
        }
        return { data: rest };
      },
      async onQueryStarted({ uid, ...patch }, { dispatch, queryFulfilled }) {
        // `updateQueryData` requires the endpoint name and cache key arguments,
        // so it knows which piece of cache state to update
        const patchRequest = dispatch(
          apiSlice.util.updateQueryData('getRequest', uid, draft => {
            // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
            Object.assign(draft, patch)
          })
        )

        const patchRequests = dispatch(
          apiSlice.util.updateQueryData('getRequests', undefined, draft => {
            const request = draft.find((request: any) => request.uid === uid)
            if (request) {
              Object.assign(request, patch)
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchRequests.undo()
          patchRequest.undo()
        }
      }
    }),
    getHouses: builder.query({
      async queryFn({ page, houseLimit=HOUSES_BATCH_LIMIT }) {
        if(page === 1) {
          const response = await firebaseTryCatchWrapper(Firebase.getInstance().loadFirstPage, { houseLimit });
          if(response.data) {
            const dataFromAdaptor = getDataFromHousesAdaptor(response.data)
            return { data: dataFromAdaptor }
          }
          return response
        }
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().loadMore);
        if(response.data) {
          const dataFromAdaptor = getDataFromHousesAdaptor(response.data)
          return { data: dataFromAdaptor }
        }
        return response;
      },
      async onQueryStarted(page, { queryFulfilled, dispatch }) {
        const { data } = await queryFulfilled;
        if (data) {
          // Merge App Houses Into First Page Using houses Adapter
          dispatch(
            apiSlice.util.updateQueryData(
              "getHouses",
              { page: 1 },
              (draft) => {
                housesAdaptor.addMany(
                  draft,
                  housesSelectors.selectAll(data)
                );
                draft.hasMore = !Firebase.getInstance().endOfPagination;
              }
            )
          );
          if (page > 1) {
            // Remove Cached Data From State If Not Page 1 Since We Already Added It To Page 1
            dispatch(
              apiSlice.util.updateQueryData(
                "getHouses",
                { page },
                (draft) => {
                  draft = housesAdaptor.getInitialState();
                }
              )
            );
          }
        }
      },
    }),
    getHouse: builder.query({
      async queryFn(houseId: string) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().getHouse, houseId);
        return response;
      },
      providesTags: (result, error, arg) => [{ type: 'House', id: arg }]
    }),
    getRelatedHouses: builder.query({
      async queryFn(options: any = {}) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().getRelatedHouses, options);
        return response;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'RelatedHouse', id: 'LIST' },
        ...result.map(({ uid }: any) => ({ type: 'RelatedHouse', id: uid }))
      ]
    }),
    getRecentHouses: builder.query<any, void>({
      async queryFn() {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().getRecentHouses);
        return response;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'RecentHouse', id: 'LIST' },
        ...result.map(({ uid }: any) => ({ type: 'RecentHouse', id: uid }))
      ]
    }),
    // Still im progress. I have to pass the userID.......
    getUserHousesOfInterest: builder.query<any, string>({
      async queryFn(userId: string) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().onUserHousesOfInterestListener, userId);
        return response;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'UserHouseOfInterest', id: 'LIST' },
      ]
    }),
    getUserHouseOfInterest: builder.query<any, { houseId: string, userId: string }>({
      async queryFn({ houseId, userId }: { houseId: string, userId: string }) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().onUserHouseOfInterestListener,  { houseId, userId });
        return response;
      },
      providesTags: ['UserHouseOfInterest']
    }),
    saveUserInterestInHouse: builder.mutation<any, any>({
      async queryFn({
        houseId,
        houseImage,
        rentFee,
        location,
        user,
        phoneNumber,
        houseURL
      }: any) {
        await firebaseTryCatchWrapper(Firebase.getInstance().doSaveUserInterestInHouse,  {
          houseId,
          houseImage,
          rentFee,
          location,
          user,
          phoneNumber,
          houseURL
        });
        return { data: '' };
      },
      // @ts-ignore - if invalidate works as expected then fix the type issue later
      invalidatesTags: [{ type: 'UserHouseOfInterest', id: 'LIST' }, 'UserHouseOfInterest'],
    }),
    declineUserInterestInHouse: builder.mutation<any, { houseId: string, houseInterestId: string }>({
      async queryFn({
        houseId,
        houseInterestId
      }: { houseId: string, houseInterestId: string }) {
        await firebaseTryCatchWrapper(Firebase.getInstance().doRemoveUserInterestInHouse,  {
          houseId,
          houseInterestId
        });
        return { data: '' };
      },
      // @ts-ignore - if invalidate works as expected then fix the type issue later
      invalidatesTags: [{ type: 'UserHouseOfInterest', id: 'LIST' }, 'UserHouseOfInterest'],
    }),
    addHouse: builder.mutation({
      async queryFn(newRequest: any) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().doAddHouse, newRequest);
        if(response.error) {
          return response
        }
        return { data: response.data.id }
      }}),
      editHouse: builder.mutation({
        async queryFn(houseUpdates: any) {
          const response = await firebaseTryCatchWrapper(Firebase.getInstance().onEditHouse, houseUpdates);
          if(response.error) {
            return response
          }
          return { data: houseUpdates };
        },
        async onQueryStarted({ uid, ...patch }, { dispatch, queryFulfilled }) {
          const patchHouse = dispatch(
            apiSlice.util.updateQueryData('getHouse', uid, draft => {
              Object.assign(draft, patch)
            })
          )
          // TODO: check if it is necessary to also update related houses
          const patchHouses = dispatch(
            apiSlice.util.updateQueryData("getHouses", { page: 1 }, draft => {
              const house = draft.entities[uid]
              if (house) {
                Object.assign(house, patch)
              }
            })
          )

          try {
            await queryFulfilled
          } catch {
            patchHouses.undo()
            patchHouse.undo()
          }
        }
      }),
    // TODO: We can also load search results in batches
    searchHouses: builder.query<any, any>({
      async queryFn(options: any) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().getHousesSearch, options);
        return response;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'HousesSearchResults', id: 'LIST' },
        ...result.map(({ uid }: any) => ({ type: 'HousesSearchResults', id: uid }))
      ]
    }),
    signUpUser: builder.mutation({
      async queryFn(userData: any) {
        const firebase = Firebase.getInstance();
        const response = await firebaseTryCatchWrapper(firebase.signUpUser, {
          email: userData.email,
          password: userData.password
        });
        if (response.data?.user) {
          const resp = await firebaseTryCatchWrapper(firebase.updateUserAfterSignup, { ...userData, uid: response.data.user.uid })
          if(resp.error){
            return resp;
          }
        }
        if(response.error) {
          return response
        }
        return { data: response.data.user.uid }
      }}),
    signInUser: builder.mutation({
      async queryFn(userData: any) {
        const firebase = Firebase.getInstance();
        const response = await firebaseTryCatchWrapper(firebase.signInUser, {
          email: userData.email,
          password: userData.password
        });
        if(response.data){
          return { data: EMAIL_PASSWORD_SIGN_IN_SUCCESS }
        }
        return response
      }
    }),
    signInWithGoogle: builder.mutation({
      async queryFn() {
        const firebase = Firebase.getInstance();
        const response = await firebaseTryCatchWrapper(firebase.doSignInWithGoogle);
        const socialAuthUser = response.data?.user
        if (socialAuthUser) {
          const resp = await firebaseTryCatchWrapper(firebase.updateUserAfterGoogleAuth, socialAuthUser)
          if(resp.error){
            return resp
          }
        }
        if(response.error) {
          return response
        }
        return { data: socialAuthUser.uid };
      }
    }),
    getCurrentUser: builder.query({
      async queryFn() {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().getCurrentUser);
        if(response.data) {
          cookie.setCookie('authUser', JSON.stringify(response.data));
        }
        return response;
      }
    }),
    sendPasswordResetEmail: builder.mutation({
      async queryFn(email: string) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().sendPasswordResetEmail, email);
        if(response.data){
          return { data: EMAIL_SENT_SUCCESS }
        }
        return response
      }
    }),
    logoutUser: builder.mutation({
      async queryFn() {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().doSignOut);
        if(response.error) {
          return response
        }
        cookie.removeCookie('authUser');
        return { data: LOGOUT_SUCCESS }
      }
    }),
    sendFeedback: builder.mutation<any, any>({
      async queryFn(feedback: any) {
        const response = await firebaseTryCatchWrapper(Firebase.getInstance().doSendFeedback, feedback);
        if(response.error) {
          return response
        }
        return { data: 'Feedback sent successfully' }
      }
    })
  })
})
const housesSelectors = housesAdaptor.getSelectors((state: any) => state);

export { housesSelectors, housesAdaptor };

export const {
  useGetRequestsQuery,
  useGetRequestQuery,
  useUpdateRequestMutation,
  useAddRequestMutation,
  useLazyGetHousesQuery,
  useGetHouseQuery,
  useGetRelatedHousesQuery,
  useSignUpUserMutation,
  useLazyGetCurrentUserQuery,
  useSignInUserMutation,
  useSignInWithGoogleMutation,
  useLogoutUserMutation,
  useSendPasswordResetEmailMutation,
  useAddHouseMutation,
  useEditHouseMutation,
  useGetRecentHousesQuery,
  useGetUserHousesOfInterestQuery,
  useGetUserHouseOfInterestQuery,
  useSaveUserInterestInHouseMutation,
  useDeclineUserInterestInHouseMutation,
  useLazySearchHousesQuery,
  useSendFeedbackMutation
} = apiSlice
